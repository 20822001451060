<template>
  <div id="anti">
    <img class="anti" :src="currentImageSrc" alt="怀国酒业投诉建议">
  </div>
  <div id="suggestion" class="suggestions">
    <div class="form-container">
      <h1>建议/投诉</h1>
      <form @submit.prevent="submitForm">
        <div class="input-group">
          <label>姓名</label>
          <input type="text" v-model="form.cusName" />
          <span v-if="nameError" class="error-message">{{ nameError }}</span>
        </div>
        <div class="input-group">
          <label>电话</label>
          <input type="tel" v-model="form.cusPhone" />
          <span v-if="phoneError" class="error-message">{{ phoneError }}</span>
        </div>
        <div class="input-group">
          <label>内容</label>
          <textarea v-model="form.cusNotes"></textarea>
          <span v-if="contentError" class="error-message">{{ contentError }}</span>
        </div>
        <div class="submit-group">
          <button type="submit" :disabled="isSubmitting">提交</button>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
import { message } from 'ant-design-vue';
import { reactive,ref } from 'vue'
import axios from 'axios';



export default {
  name: "ServiceCenter",
  components:{
  },
  data() {
    return {
      desktopImage: require('@/assets/anti/AntiCounterfeit.jpg'),
      mobileImage: require('@/assets/anti/PhoneAntiCounterfeit.jpg'),
      currentImageSrc: ''
    };
  },
  setup() {
    const form = reactive({
      cusName: '',
      cusPhone: '',
      cusNotes: '',
      notesType:'1',
      sysOrgCode:'A01'
    });
    const nameError = ref('');
    const phoneError = ref('');
    const contentError = ref('');
    const isSubmitting = ref(false);

    const submitForm = () => {
      // 重置所有错误信息
      nameError.value = '';
      phoneError.value = '';
      contentError.value = '';
      isSubmitting.value = false;

      // 校验名称必填
      if (!form.cusName) {
        nameError.value = '请填写姓名';
      }

      // 校验电话号码
      if (!form.cusPhone) {
        phoneError.value = '请填写电话';
      } else if (form.cusPhone.length !== 11 || !/^\d+$/.test(form.cusPhone)) {
        phoneError.value = '电话号码必须为11位数字';
      }

      // 校验内容必填
      if (!form.cusNotes) {
        contentError.value = '请填写内容';
      }

      // 如果有任何一个校验错误，则不提交表单
      if (nameError.value || phoneError.value || contentError.value) {
        return;
      }

      // 设置提交状态，提交表单逻辑
      isSubmitting.value = true;
      console.log(form);
      axios.post('https://www.guorundianshang.top:9999/notes/erpCustomerNotes/add', form)
          .then(response => {
            message.success('提交成功！感谢您宝贵的反馈建议！', 3);
            console.log(response.data);
            // 处理提交成功的逻辑
            form.cusName = '';
            form.cusPhone = '';
            form.cusNotes = '';
          })
          .catch(error => {
            message.error('提交失败: ' + error.message);
            console.error(error);
          })
          .finally(() => {
            isSubmitting.value = false;
          });
      //限制重复提交
      setTimeout(() => {
        isSubmitting.value = false;
      }, 10000);
    }

    return { form, submitForm, nameError, phoneError, contentError, isSubmitting };
  },
  mounted() {
    if (this.$route.hash) {
      const element = document.getElementById(this.$route.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        const rect = element.getBoundingClientRect();
        const top = rect.top + window.scrollY - 100;
        window.scrollTo({
          top: top,
          behavior: 'smooth'
        });
      }
    }
    this.currentImageSrc = this.isMobileDevice() ? this.mobileImage : this.desktopImage;
  },
  methods: {
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  }
}
</script>

<style scoped>
.anti {
  width: 100%;
}

.suggestions{
  height: 600px;
  background-image: url('~@/assets/home/backgroundImag.jpg');
}

.form-container {
  max-width: 450px;
  margin: 40px auto;
  padding: 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.form-container h1 {
  margin-bottom: 20px;
  font-size: 30px;
}

.input-group {
  margin-bottom: 10px;
  font-size: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}
.error-message {
  color: red;
  font-size: 0.8em;
}

.input-group input,
.input-group textarea {
  background-color: transparent;
  width: 100%;
  padding: 8px;
  border: none; /* 移除所有边框 */
  border-bottom: 1px solid #ccc; /* 仅添加下边框 */
  border-radius: 0; /* 移除圆角 */
}

.submit-group {
  margin-top: 20px;
}

.submit-group button {
  width: 330px;
  font-size: 20px;
  padding: 10px 15px;
  border: none;
  background-color: #bdc3c7;
  color: black;
  cursor: pointer;
  border-radius: 4px;
}


@media screen and (max-width: 600px) {
  .form-container {
    max-width: 350px;
    margin: 40px auto;
    padding: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .submit-group button {
    width: 290px;
  }
}

</style>
