<template>
  <div class="container">
    <div class="logo">
      <router-link to="/" class="logo-link"><img class="logo-img" :src="require('@/assets/homeLogo.png')" alt="怀国酒业Logo"></router-link>
    </div>
    <div class="menu">
      <button class="menu-toggle" @click="toggleMenu">☰</button> <!-- 添加一个切换按钮 -->
      <div class="index_nav" :class="{'is-active': menuActive}">
        <ul class="Ul">
          <li class="menu-item" v-for="item in menuItems" :key="item.name">
            <router-link
                :to="item.route"
                class="menu-link"
                :class="{ active: $route.path === item.route }"
                @click="closeMenu"
            >
              {{ item.name }}
            </router-link>
            <ul class="submenu" v-if="item.submenu">
              <li v-for="subItem in item.submenu" :key="subItem.name">
                <router-link :to="subItem.route" class="submenu-link" @click="closeMenu">{{ subItem.name }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  data() {
    return {
      menuActive: false,
      menuItems: [
        { name: '首页', route: '/' },
        {
          name: '集团介绍',
          route: '/introduction',
          submenu: [
            { name: '公司简介', route: { name: 'introduction', hash: '#company-profile' } },
            { name: '生产工艺', route: { name: 'introduction', hash: '#production-process' } },
            { name: '文化理念', route: { name: 'introduction', hash: '#cultural-concept' } },
            { name: '品牌故事', route: { name: 'introduction', hash: '#brand-story' } },
            { name: '发展历程', route: { name: 'introduction', hash: '#development-path' } },
            { name: '品牌荣誉', route: { name: 'introduction', hash: '#brand-honors' } },
            { name: '社会责任', route: { name: 'introduction', hash: '#social-responsibility' } },
          ]
        },
        { name: '新闻资讯', route: '/news' },
        {
          name: '产品中心',
          route: '/productPage',
          submenu: [
           /* { name: '口粮系列', route: { name: 'productPage', hash: '#口粮系列' } },
            { name: '品质系列', route: { name: 'productPage', hash: '#品质系列' } },*/
            { name: '臻品系列', route: { name: 'productPage', hash: '#臻品系列' } },
            { name: '定制系列', route: { name: 'productPage', hash: '#定制系列' } },
          ]
        },
        { name: '服务中心', route: '/serviceCenter' },
        { name: '厂区环境', route: '/factoryEnvironment#production' },
        { name: '招商加盟', route: '/joinPage' },
        { name: '联系我们', route: '/contactUs' },
        /*{
          name: '相关信息',
          route: '/encyclopedia#huaiguo1',
          submenu: [
            { name: '贵州怀国酒业简介', route: { name: 'encyclopedia', hash: '#huaiguo1' } },
            { name: '怀国酒业简介', route: { name: 'encyclopedia', hash: '#huaiguo2' } },
            { name: '遵协简介', route: { name: 'encyclopedia', hash: '#zunxie' } },
            { name: '郑厂长简介', route: { name: 'encyclopedia', hash: '#zcz' } },
          ]
        },*/
        { name: '相关信息', route: '/encyclopedia#huaiguo' }
      ],
    };
  },
  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
    closeMenu() {
      this.menuActive = false;
    },
  },
};
</script>


<style scoped>
.container {
  display: flex;
  align-items: center;
  background: white;
  height: 110px;
}

.logo .logo-link {
  display: flex;
  align-items: center;
}

.logo-img {
  max-width: 100%;
}

.menu {
  flex-grow: 1;
  text-align: right;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
}

.index_nav {
  display: flex;
  justify-content: flex-end;
}

.Ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 18px;
}

.menu-item {
  position: relative;
  padding-right: 20px;
}

.menu-link, .submenu-link {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: black;
  transition: color 0.5s; /* 添加颜色变化动画 */
}

.menu-link:hover, .submenu-link:hover {
  color: #1c61ae; /* 激活时的颜色 */
}


.submenu {
  display: none;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 150%;
}

.submenu-link{
  text-align: center; /* 使字体居中 */
}

.menu-item:hover .submenu {
  display: block;
}
.menu-link.active, .submenu-link.active {
  color: #1c61ae; /* 激活状态的字体颜色 */
}

.menu-link, .submenu-link {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: black;
  position: relative; /* 为下划线动画做准备 */
  transition: color 0.5s; /* 颜色变化动画 */
}

.menu-link::after, .submenu-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #1c61ae; /* 下划线颜色 */
  transition: width 0.3s ease, left 0.3s ease; /* 下划线动画效果 */
}

.menu-link.active::after, .submenu-link.active::after {
  content: '';
  position: absolute;
  width: 100%; /* 激活状态下划线的宽度 */
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #1c61ae; /* 激活状态下划线的颜色 */
}

.menu-link:hover, .submenu-link:hover {
  color: #1c61ae; /* 激活时的颜色 */
}

.menu-link:hover::after, .submenu-link:hover::after {
  width: 100%; /* 下划线满宽度 */
  left: 0; /* 从左侧开始 */
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .index_nav {
    display: none;
  }

  .index_nav.is-active {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
  }

  .Ul {
    flex-direction: column;
    padding-left: 0; /* 移除左侧内边距 */
  }

  .menu-item {
    width: 100%;
  }

  .menu-link, .submenu-link {
    text-align: left; /* 文本左对齐 */
    padding: 10px 15px; /* 调整内边距，确保文本不会太靠近边缘 */
    border-bottom: 1px dashed #ddd; /* 添加浅色虚线分隔符 */
  }

  .submenu {
    position: static;
    width: 100%;
    box-shadow: none;
    margin-top: 5px; /* 在二级菜单上方添加一些空间 */
  }
  .submenu-link {
    padding-left: 30px; /* 为二级菜单添加缩进 */
  }
  .menu-item:last-child .menu-link,
  .submenu li:last-child .submenu-link {
    border-bottom: none; /* 最后一个菜单项不显示分隔线 */
  }
  .menu-link.active, .submenu-link.active {
    border-bottom: none; /* 激活时不显示虚线 */
  }
}
</style>


