<template>
  <div class="company-inst">
    <div class="company-inst__info">
      <div class="company-inst__info-item">
        <strong class="company-inst__title">百年怀国</strong>
        <p class="company-inst__subtitle">
          115年专注酿造高品质酱酒的白酒品牌
        </p>
      </div>
    </div>
    <div class="info-page">
      <div class="company-inst__info-box">
        <div>
          <div id="company-info-page-div" class="company-inst__info-bg">
            <div>
              <div class="company-inst__info-list">
                <div class=" company-inst__info-list-item">
                  <div class="company-inst__info-container  ">
                    <div class="company-inst__info-item">
                      <div class="company-inst__info-item-container  ">
                        <div class="company-inst__info-item">
                          <div class="company-inst__image">

                            <img src="~@/assets/home/company/info/icon1.png"
                                 alt="怀国酒业" title="怀国酒业" la="la">

                          </div>
                          <p class="e_text-11 ">
                            7.5km²核心产区
                          </p>
                          <p class="e_text-14 ">
                            怀国酒业制酒、制曲车间沿赤水河而建，厂区地表为茅台紫红土壤，采用赤水河水，海拔450米，与茅台共享水源、土壤、空气、气候、酿酒微生物等环境资源。
                          </p></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" company-inst__info-list-item">
                  <div class="company-inst__info-container  ">
                    <div class="company-inst__info-item">
                      <div class="company-inst__info-item-container  ">
                        <div class="company-inst__info-item">
                          <div class="company-inst__image">
                            <img src="~@/assets/home/company/info/icon2.png"
                                 alt="怀国酒业" title="怀国酒业" la="la">
                          </div>
                          <p class="e_text-11 ">
                            怀国酱香工艺
                          </p>
                          <p class="e_text-14 ">
                            怀国酒业酱酒均为茅台初代老厂长、国酒大师郑义兴秘方所酿，和茅台同根同源同配方。63°高温发酵，在高温产香的微生物体系环境中产生细菌、酵母菌、霉菌和放线菌等多菌种发酵而成。
                          </p></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" company-inst__info-list-item">
                  <div class="company-inst__info-container  ">
                    <div class="company-inst__info-item">
                      <div class="company-inst__info-item-container  ">
                        <div class="company-inst__info-item">
                          <div class="company-inst__image">

                            <img src="~@/assets/home/company/info/icon3.png"
                                 alt="怀国酒业" title="怀国酒业" la="la">

                          </div>
                          <p class="e_text-11 ">
                            专业技术团队
                          </p>
                          <p class="e_text-14 ">
                            冯小宁、陈仁远等中国酿酒大师、中国首席白酒品酒师、国家级白酒评委等国家级行业领军人物。经过大师勾调的酱酒，酒体层次更丰富、口感更柔和。
                          </p></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeCompanyInst"
}
</script>

<style scoped>

.company-inst {
  width: 100%;
  height: 88vh;
}

.company-inst__title{
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  font-size: 32px;
  text-align: center;
  font-family: SourceHanSansCNBold;
}


.company-inst__info{
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  display-radio: flex;
  background-color: white;
}

.company-inst__info> .company-inst__info-item {
  flex: 1;
  max-width: 100%;
}

.company-inst__subtitle {
  text-align: center;
  font-size: 20px;
  font-family: SourceHanSansCNBold;
  color: black;
}

.info-page .company-inst__info-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  transition-property: all;
  transition-timing-function: cubic-bezier(0, 0, 1, 1);
  transition-radio: hover;
  transition-duration: 0.8s;
  display-radio: flex;
}

/*悬浮背景颜色*/
/*
.info-page .company-inst__info-container:hover {
  background-color: #000000;
  opacity: 0.5
}
*/

#company-info-page-div {
  background-image: url('~@/assets/home/company/info/background.jpg');
}

.info-page .company-inst__info-bg {
  background-fill-value: background-image;
  transition-property: all;
  transition-radio: active;
  transition-timing-function: cubic-bezier(0, 0, 1, 1);
  display-radio: 1;
  background-attachment: fixed;
  background-size: 100% 100%
}

.info-page .company-inst__info-bg .company-inst__info-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-width: 100%
}


.info-page .company-inst__info-bg .company-inst__info-list-item {
  flex: 0 0 33.3%;
  position: relative
}

.info-page .company-inst__image {
  overflow: hidden;
  max-width: 60px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  display-radio: 1
}

.info-page .e_text-11 {
  line-height: normal;
  font_radio: 2;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-size: 26px;
  margin-top: 50px;
  margin-bottom: 47px;
  font-weight: normal;
  font-family: SourceHanSansCNBold;
  display-radio: 1
}

/*选择蓝色背景*/
/*.info-page .company-inst__info-bg:active .company-inst__info-container {
  background: rgba(19, 129, 198, 0.8)
}*/

.info-page .company-inst__info-item-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  padding-top: 0px;
  margin-top: 35vh;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0, 0, 1, 1);
  display-radio: flex;
  margin-bottom: 60px
}


.info-page .company-inst__info-container:hover .company-inst__info-item-container {
  margin-top: 18vh
}

.info-page .e_text-14 {
  line-height: 1.7;
  font_radio: 2;
  font-family: SourceHanSansCNBold;
  text-align: center;
  font-size: 18px;
  color: rgba(255, 255, 255, 0);
  margin-left: auto;
  margin-right: auto;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0, 0, 1, 1);
  font-weight: normal;
  display-radio: -webkit-box;
  width: 90%;
  max-width: none;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden
}

.info-page .company-inst__info-container:hover .e_text-14 {
  color: rgba(255, 255, 255, 1)
}

@media screen and (max-width: 768px) {
  #company-info-page-div {
    background-image: url('~@/assets/home/company/info/phoneBackground.png');
  }
  .info-page .company-inst__info-bg {
    background-fill-value: background-image;
    transition-property: all;
    transition-radio: active;
    transition-timing-function: cubic-bezier(0, 0, 1, 1);
    display-radio: 1;
    background-attachment: fixed;
    background-size: 100% 20%
  }
  .company-inst {
    height: auto;
  }

  .company-inst__title {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .company-inst__subtitle {
    font-size: 16px;
  }

  .info-page .company-inst__info-bg .company-inst__info-list-item {
    flex: 0 0 100%; /* 在手机上使每个项占据一整行 */
  }

  .info-page .company-inst__image {
    max-width: 70px; /* 调整图标大小 */
  }

  .info-page .e_text-11 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .info-page .e_text-14 {
    font-size: 16px;
    -webkit-line-clamp: unset;
    overflow: visible;
  }

  .info-page .company-inst__info-container:hover .company-inst__info-item-container {
    margin-top: 10vh;
  }

  .info-page .company-inst__info-item-container {
    margin-top: 20vh;
  }
}

</style>
