<template>
  <a-layout>
    <a-layout>
      <a-layout class="menu-container">
        <menu-item></menu-item>
      </a-layout>
      <a-layout>
        <router-view></router-view>
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script>
import MenuItem from "@/components/home/MenuItem.vue"


export default {
  name: 'HomePage',
  components: {
    MenuItem,
  },
  data() {
    return {};
  }
};
</script>
<style scoped>
.menu-container {
  position: sticky;
  top: 0;
  background: #fff; /* 如果需要背景色，可以添加 */
  z-index: 999; /* 如果需要放在内容之上，可以添加 z-index */
  border-bottom: 1px solid #f0f0f0;

}
</style>
