<template>
  <div class="footer">
    <div class="footer_top">
      <ul>
        <li>
          <h2>京东店铺</h2>
          <div>
            <a href="https://mall.jd.com/index-12231325.html?from=pc" target="_blank">
              <img src="~@/assets/home/footer/jd.png" class='footer-img' alt="怀国酒业京东店铺">
            </a>
          </div>
        </li>
        <li>
          <h2>天猫店铺</h2>
          <div>
            <a href="https://huaiguojiulei.tmall.com/" target="_blank">
              <img src="~@/assets/home/footer/tm.png" class='footer-img' alt="怀国酒业天猫店铺">
            </a>
          </div>
        </li>
        <li>
          <h2>微信公众号</h2>
          <div>
            <img src="~@/assets/home/footer/gzh.png" class='footer-img' alt="怀国酒业微信公众号">
          </div>
        </li>
        <li>
          <h2>抖音号</h2>
          <div>
            <img src="~@/assets/home/footer/dy.png" class='footer-img' style="transform: scale(1.4);" alt="怀国酒业抖音号">
          </div>
        </li>
      </ul>
    </div>
    <div class="fot_shopName">
      <ul>
        <li>
          <p class="fot_title">联系我们</p>
          <p class="fot_text">电话：400-133-6160</p>
          <p class="fot_text">邮箱：huaiguojiuye@163.com</p>
          <p class="fot_text">公司名称：贵州怀国酒业（集团）有限公司</p>
          <p class="fot_text">公司地址：中国贵州省仁怀市茅台镇</p>
        </li>
      </ul>
    </div>
    <div class="copyright">
      <p>版权所有 © 2023 贵州怀国酒业（集团）有限公司
        <a target="_blank" href="https://beian.miit.gov.cn" style="color: black">黔ICP备2023008349号</a></p>
    </div>
  </div>
</template>
<script>

export default {
  name: 'FooterPage',
  data() {
    return {}
  }
}

</script>

<style scoped>
ol, ul, li {
  list-style: none;
}

/*footer*/
.footer {
  height: 100%;
  overflow: hidden;
  background: url('~@/assets/home/backgroundImag.jpg') no-repeat center center/100% 100%;
  position: relative; /* 添加相对定位 */
}

.footer_top {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-img{
  width: 130px;
  border-radius: 10px;
}

.footer_top ul {
  display: flex;
}

.footer_top ul li {
  flex: 1;
  text-align: center;
}

.footer_top ul li h2 {
  font-size: 32px;
  color: black;
  font-weight: bold;
  padding: 100px 0 28px 0;
  font-family: SourceHanSansCNBold;
}

.footer_top ul li p {
  font-size: 20px;
  color: black;
  line-height: 40px;
}

.footer_top ul li p span {
  display: block;
  font-family: Helvetica;
}

.fot_shopName {
  text-align: center;
  padding-top: 80px;
}

.fot_title{
  font-weight: bold;
  font-size: 32px;
  text-align: center;
}
.fot_text{
  font-size: 18px;
  text-align: center;
}

.copyright {
  height: 25px;
  bottom: 200px; /* 放置在底部 */
  width: 100%; /* 横跨全宽 */
  text-align: center;
  padding-top: 10px;
}

/* 基本样式 */

/* 媒体查询 - 针对手机屏幕 */
@media screen and (max-width: 600px) {
  .footer_top ul {
    flex-wrap: wrap; /* 允许子项换行 */
    margin-left: -30px;
  }

  .footer_top ul li {
    flex: 0 0 50%; /* 每个li元素占据50%的宽度 */
    max-width: 50%; /* 最大宽度也设置为50% */
    box-sizing: border-box; /* 确保padding和border包含在宽度内 */
  }

  .footer_top ul li h2 {
    font-size: 20px; /* 减小标题字体大小 */
    padding: 20px 0; /* 减少上下内边距 */
  }

  .footer-img{
    width: 100px;
  }

  .fot_title {
    font-size: 20px; /* 减小字体大小 */
  }

  .fot_text {
    font-size: 16px; /* 减小字体大小 */
  }
  .fot_shopName {
    text-align: center;
    padding-top: 10px;
    margin-left: -40px;
  }

  .copyright {
    height: 40px;
  }
}


</style>
