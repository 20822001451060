<template>
  <div class="window-bin">
    <div class="window-back">
      <div class="title-box">
        <div class="title-name">
          <strong>产品中心</strong>
          <hr class="title-divider">
        </div>
      </div>
      <div class="product-box" style="display: flex; justify-content: space-between;">
        <!-- 左边的按钮 -->
<!--        <button v-if="currentIndex > 0" @click="prev">上一个</button>-->
        <!-- 循环显示当前索引下的三个产品 -->
        <div class="product-item" v-for="product in currentProducts" :key="product.id">
          <router-link :to="product.link" active-class="checked">
            <img class="img-responsive" :src="product.imageSrc" alt="怀国酒业产品">
            <p class="product-series">{{ product.series }}</p>
            <p class="product-detail">查看详情&gt;</p>
          </router-link>
        </div>
        <!-- 右边的按钮 -->
<!--        <button v-if="currentIndex < products.length - 3" @click="next">下一个</button>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeProduct",
  components: {},
  props: {
  },
  // 这里用于存放数据
  data() {
    return {
      currentIndex: 0, // 当前显示的第一个产品的索引
      products: [ // 所有产品的数据
        // 用实际产品数据替换以下示例数据
        { id: 1, series: '怀国15', imageSrc: require('@/assets/home/product/15.jpg'), link: '/productPage#臻品系列' },
        { id: 2, series: '怀国20', imageSrc: require('@/assets/home/product/3.png'), link: '/productPage#臻品系列' },
        { id: 3, series: '怀国酱酒', imageSrc: require('@/assets/home/product/bnm.jpg'), link: '/productPage#臻品系列' },
        { id: 4, series: '定制酒', imageSrc: require('@/assets/home/product/4.png'), link: '/productPage#定制系列' },
      ],
    }
  },
  computed: {
    // 计算当前应该显示的三个产品
    currentProducts() {
      return this.products.slice(this.currentIndex, this.currentIndex + 4);
    },
  },
  methods: {
    // 显示下三个产品
    next() {
      if (this.currentIndex < this.products.length - 3) {
        this.currentIndex++;
      }
    },
    // 显示前三个产品
    prev() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
  },
}
</script>

<style scoped>
.window-back {
  background-image: url('~@/assets/home/backgroundImag.jpg');
  width: 100%;
  height: 88vh;
}

.title-box {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 80px
}

.title-name strong {
  display: block;
  font-size: 30px;
  text-align: center;
  font-family: SourceHanSansCNBold;
}

.title-divider {
  margin-top: 20px; /* 在标题下方留出20px的空间 */
  border: 0; /* 移除默认边框 */
  height: 2px; /* 线条高度 */
  background-color: #333; /* 线条颜色，可根据设计调整 */
  width: 3%; /* 线条宽度，可根据设计调整 */
  margin-left: auto; /* 水平居中 */
  margin-right: auto; /* 水平居中 */
}

.product-box {
  margin: 100px;
}

.product-item {
  margin: 0 7%;
  height: 100%;
  float: left;
  position: relative;
  transition: background-color 0.3s; /* 添加渐变效果 */
  cursor: pointer;
}

.product-item:hover {
  transform: scale(1.01); /* 鼠标悬浮时轻微放大图片 */
  transition: transform 0.3s ease; /* 平滑过渡效果 */
}

.product-series {
  margin-top: 30px;
  display: block;
  font-size: 25px;
  color: black;
  text-align: center;
  font-family: SourceHanSansCNBold;
}

.product-detail {
  display: block;
  font-size: 15px;
  color: black;
  text-align: center;
  font-family: SourceHanSansCNBold;
}
@media (max-width: 768px) {
  .product-box {
    flex-direction: column;
  }
  .window-back {
    height: auto;
  }

  .title-box {
    padding-top: 40px;
  }

  .title-name strong {
    font-size: 24px;
  }

  .title-divider {
    width: 10%; /* 在手机上增加线条宽度以更显眼 */
  }

  .product-box {
    margin: 30px 0px 0px 30%;
  }

  .product-item {
    margin-bottom: 50px;
    width: 45%; /* 增加宽度以适应屏幕 */
  }

  .product-series {
    font-size: 20px;
  }

  .product-detail {
    font-size: 14px;
  }
}
</style>
