<template>
  <div class="encyclopedia-page">
    <!-- 导航栏 -->
<!--    <div class="navbar">
      <router-link class="nav-link" :to="{ name: 'encyclopedia', hash: '#huaiguo' }"
                   :class="{ active: huaiguo }"
                   @click="toggleEnvironment('huaiguo')">
        贵州怀国酒业简介
      </router-link>
      <router-link class="nav-link" :to="{ name: 'encyclopedia', hash: '#huaiguo2' }"
                   :class="{ active: huaiguo2 }"
                   @click="toggleEnvironment('huaiguo2')">
        怀国酒业简介
      </router-link>
      <router-link class="nav-link" :to="{ name: 'encyclopedia', hash: '#zunxie' }"
                   :class="{ active: zunxie }"
                   @click="toggleEnvironment('zunxie')">
        遵协简介
      </router-link>
      <router-link class="nav-link" :to="{ name: 'encyclopedia', hash: '#zcz' }"
                   :class="{ active: zcz }"
                   @click="toggleEnvironment('zcz')">
        郑厂长简介
      </router-link>
    </div>-->

    <div id="huaiguo" v-if="huaiguo" class="encyclopedia-list">
      <h1>贵州怀国酒业（集团）有限公司介绍</h1>

      <p>贵州怀国酒业（集团）有限公司起源于1908年成立的私人老烧坊，坐落于中国酱酒核心产区贵州茅台镇赤水河，总占地300余亩，员工400多人，是集白酒生产酿造、研发、包装销售为一体的集团公司。怀国酒业白酒为初代茅台酒厂老厂长“郑义兴”配方所酿，传承茅香品质。
      公司秉承“怀工匠之心，酿国之好酒”的经营理念，坚持生产人们喝得起的好酱酒，旗下拥有怀国、怀国梦、滴粮等多个自主品牌。怀国酒业拥有万亩原粮种植基地，四大生产基地、酿造基地，年产值5000多吨，储酒20000多吨，产品覆盖全国23个省份和地区。
      怀国酒业被CCTV评为国货之光巡展品牌；蝉联年度白酒行业领军品牌金谱奖；利比里亚共和国驻华大使馆指定用酒，十周年国礼品牌，当选贵州白酒行业研究会副会长单位。
      </p>
      <h2>企业文化</h2>
      <h3>企业使命</h3>
      <p>酿造美好生活，传承中国文化</p>
      <h3>愿景</h3>
      <p>中国十大酱酒品牌</p>
      <p>中国互联网温度白酒第一品牌</p>
      <h3>价值观</h3>
      <p>客户第一，品质至上，传承创新，高效发展</p>
      <h3>企业战略</h3>
      <p>两个“坚持”原则：</p>
      <p>坚持用“储存五年以上”老基酒</p>
      <p>坚持走“高质量服务”发展之路</p>
      <h3>企业理念</h3>
      <p>怀工匠之心，酿国民之酒</p>

      <h2>产品特点</h2>
      <h3>品质特点</h3>
      <p>传承正宗茅香经典，酱香浓郁、酒体醇厚</p>
      <h3>生产工艺</h3>
      <p>传承茅台初代老厂长、国酒大师郑义兴配方，坚持用5年以上老基酒调酒，坚持12987酿酒工艺，坚持不到时间不取酒，只酿纯粮酱酒。</p>
      <h3>产品系列（部分）</h3>
      <p>千里江山、怀国1935、怀国国宾15、怀国国宾20、怀国酱酒1915巴拿马纪念酒等。</p>

      <h2>发展历程</h2>
      <p>1908年，成立私人老烧坊，开始酿造怀国酒。</p>
      <p>2008年，怀国品牌管理有限公司成立，怀国酒畅销全国。</p>
      <p>2022年，成立怀国酒业集团，走多品牌发展之路。</p>
      <h2>企业荣誉</h2>
      <p>2022-2023年度品牌节白酒行业领军品牌金谱奖</p>
      <p>CCTV“国货之光”巡展品牌</p>
      <p>“贵州省酒文化研究会”副会长单位</p>
      <p>“十周年”国礼品牌</p>
      <p>入选品牌强国优选工程</p>
      <p>中国人保承保产品</p>
      <p>白酒领域AAA级信用企业</p>
      <p>质量服务信誉AAA级示范单位</p>
      <p>白酒领域诚信认证供应商</p>
      <p>守信践诺营商企业</p>
      <p>2022客户满意诚信单位</p>
    </div>

    <div id="huaiguo2" v-if="huaiguo2" class="encyclopedia-list">
      <h1>怀国简介</h1>
      <p>怀国是贵州怀国酒业（集团）有限公司旗下品牌产品，源自于1908年创立的私人老烧坊。</p>
      <h2>公司介绍</h2>
      <p>贵州怀国酒业（集团）有限公司坐落于中国酱酒核心产区贵州茅台镇赤水河，总占地300余亩，是集白酒生产酿造、研发、包装销售为一体的集团公司。</p>
      <h2>品牌文化</h2>
      <p>怀工匠之心，酿国民之酒</p>
      <h2>品质特征</h2>
      <p>怀国酒酱香突出，柔而舒适，酒体醇厚净爽，幽雅细腻，酒花密集，回味悠长，隔夜留香等特点。</p>
      <h2>产品工艺</h2>
      <p>怀国酒为茅台初代老厂长、“国酒大师”郑义兴秘方酿造，传承郑义兴117味制曲秘方；采用当地优质红缨子高粱为酿酒原料，坚持传统坤沙大曲酿造，严格执行12987酿酒工艺。在恒温恒湿的窖藏环境中以陶坛密封，坚持用5年以上老基酒调酒，具有酱香浓厚，入口醇香、回甘突出等特点。</p>
      <h2>产品系列</h2>
      <p>千里江山、怀国1935、怀国国宾15、怀国国宾20、怀国酱酒1915巴拿马纪念酒等。</p>
      <h2>品牌传说</h2>
      <p>20世纪初的1908年，怀国私人烧坊在“实业救国”的热潮中创立，成立私人老烧坊开始酿酒。中间经历了战争关闭、破产倒闭。</p>
      <p>新中国成立后，国家鼓励白酒产业发展，并制定了酿酒行业十年规划，怀国酒恢复生产。</p>
      <p>改革开放后，响应国家“普通酒向优质酒转变”政策，怀国酒开始技术和配方的创新发展之路，一方面搭建优质高粱产值基地，另一方面精心研制117味正宗茅香配方，传承茅台初代老厂长、“国酒大师”郑义兴117味制曲秘方。</p>
      <p>怀国酒在经历各种变动后走完第一个百年，坚持古法纯粮酿造，保留了相对完整、正宗的茅香酿酒工艺和配方。怀国酒立志通过超高性价比提供优质产品和服务，提升人们美好生活品质，传播中国传统文化。</p>
      <h2>发展历程</h2>
      <p>1908年，成立私人老烧坊，开始酿造怀国酒</p>
      <p>1949年，恢复生产</p>
      <p>1983年，复原郑义兴秘方，传承117味中草药制曲配方，怀国酒并全面恢复生产</p>
      <p>2008年，怀国品牌管理有限公司成立，怀国酒畅销全国</p>
      <p>2022年，成立怀国酒业集团，走多品牌发展之路</p>
      <h2>所获荣誉</h2>
      <p>2022-2023年度品牌节白酒行业领军品牌金谱奖</p>
      <p>CCTV“国货之光”巡展品牌</p>
      <p>“十周年”国礼品牌</p>
      <p>入选品牌强国优选工程</p>
    </div>

    <div id="zunxie" v-if="zunxie" class="encyclopedia-list">
      <h1>遵协简介</h1>
      <p>遵协是遵义市酱酒产业发展（集团）有限公司及遵义红粱产业（集团）有限责任公司联合生产制造的产品，位于贵州省遵义市茅台镇。</p>
      <h2>公司介绍</h2>
      <p>遵义市酱酒产业发展（集团）有限公司属于国有独资企业，坐落于中国酱酒核心产区贵州茅台镇赤水河畔，是集生产酿造、研发、包装销售为一体的集团公司。</p>
      <p>遵协与茅台、习酒并列贵州三大国营酱香品牌，遵协由遵义市酱酒产业发展（集团）有限公司联合国营遵义市红粱产业（集团）有限公司生产制造，采用茅台、珍酒等头部酒厂同源红缨子高粱、117味“茅系”制曲秘方酿造。</p>
      <h2>产品系列</h2>
      <p>遵协·尊品、遵协·1935、遵协·珍藏30</p>
      <h2>品质特点</h2>
      <p>遵协酒色清透明、酱香突出、优雅细致、醇香馥郁、幽雅细腻、入口柔绵、清冽甘爽、酒体醇厚丰满、回味悠长、空杯留香持久的特点，是典型的中国酱香型风格。</p>
      <h2>生产工艺</h2>
      <p>得天独厚的品种、气候、土壤条件，造就了遵义红粱独特的品质。而以茅台酒厂订单收购价为风向标，红缨子高粱也成为了全世界“身价最高的高粱”，遵义市红粱产业（集团）有限公司种植的红缨子高粱每年都向茅台、习酒、珍酒等白酒头部企业大量供材。2023年2月，由遵义市农业农村局牵头，联合遵义市市场监管局、遵义市红粱产业（集团）有限责任公司、贵州茅台酒厂（集团）红缨子农业科技发展有限公司、遵义市产品质量检验检测院等单位共同建设的国家高粱（红粱）种植标准化示范区被纳入第十一批国家农业标准化试点示范区名单，成为全国唯一一个入选的国家级高粱标准化示范区项目。遵协酒使用遵义市红粱产业（集团）有限公司种植的，和茅台、习酒、珍酒同样的优质红缨子高粱为酿酒原料，颗粒坚实饱满、粒小皮厚，其支链淀粉含量达88%以上，十分有利于酱香白酒工艺的多次翻烤。同时，红缨子高粱富含2%-2.5%的单宁，这也有利于白酒在发酵期间形成多种有利于酱香产生的前体物质，这也是传统酱香白酒“酱味”的由来。</p>
      <p>遵协采用传统大曲坤沙固态发酵，严格执行“12987”世代传承酿造工艺，一年期间、二次投料、九次蒸馏、八次发酵、七次取酒。一批基酒的酿造需经历春、夏、秋、冬一年四季，二十四节气。生产季节性较强，端午踩曲、重阳投料。</p>
      <p>在水质及环境方面，遵协酒采用清澈的赤水河水，赤水河水入口微甜、常年水质良好，无溶解杂质的水经过蒸馏后酿出的酒尤为甘甜。与此同时，遵义酒产地茅台镇具有极其特殊的气候、地理条件，冬暖夏热的气候十分有利于酒料的发酵和熟化，也很有利于酱香酒中香气成分的微生物产生。从粮食到工艺再到得天独厚的酿酒环境，造就遵协正宗品质酱香白酒。</p>
      <h2>历史文化</h2>
      <p>1956年，茅台酒厂开展以提高产品质量为中心的运动，并向遵义市政府求助派遣专家科研。经过3年不断研究，终于从茅台酒中分离出了最能决定口感及“茅味”酱香的核心百味“茅系制曲秘方”，后这份配方文件被交给遵协酒厂。</p>
      <p>20世纪80年代，市场经济的蓬勃发展带动了白酒行业的高度发展，遵义市政府为进一步稳定和提升白酒质量，推动和扩大整个白酒产业发展，成立了国营遵义酱酒集团，授予“茅系核心制曲秘方”使用权；同时成立国营红粱产业集团，也就是后来为茅台、习酒、珍酒提供粮食供应的国企，遵义酱酒专用和茅台同品质酿酒原料和12987酿酒工艺。</p>
      <p>遵协背靠遵义酱酒集团和红粱产业集团“双国企”，肩负国有企业振兴酱酒、推动行业发展、回馈社会、酿造老百姓喝得起的酱香好白酒的使命，并为之不断奋斗。</p>
    </div>
    <div id="zcz" v-if="zcz" class="encyclopedia-list">
      <h1>郑厂长简介</h1>

      <p>郑厂长是贵州郑厂长酒业有限公司旗下白酒产品，由茅台初代老厂长、国酒大师郑义兴秘方酿造，酱香突出、优雅细致、茅香十足。</p>
      <h2>公司介绍</h2>
      <p>贵州郑厂长酒业有限公司坐落于中国酱酒核心产区贵州茅台镇赤水河，是集白酒生产酿造、研发、包装销售为一体的集团公司，目前已上线郑厂长茅香10味、郑厂长茅香15味、郑厂长茅香20味等产品系列。</p>
      <h2>产品系列</h2>
      <p>郑厂长·茅香10味、郑厂长·茅香15味、郑厂长·茅香20味</p>
      <h2>产品特点</h2>
      <p>酱香浓厚、回甘突出、酒体微黄、酒线细长、酒花密集、挂壁明显、隔夜留香。</p>
      <h2>生产工艺</h2>
      <p>郑厂长酒采用郑义兴117味百年制曲秘方，传承茅香经典；优选当地优质红缨子高粱为酿酒原料，严格执行12987酿酒工艺，在恒温恒湿的窖藏环境中以陶坛密封，加上茅台镇独有的气候、地理条件，冬暖夏热的气候，赤水河水，利于酒料发酵和熟化和微生物发酵。</p>
      <p>郑厂长酱酒严格执行12987酿酒工艺，一年，在这一年期间，须二次投料、九次蒸馏、八次发酵、七次取酒，一批基酒的酿造需经历春、夏、秋、冬一年四季，二十四节气。生产季节性较强，端午踩曲、重阳投料，孕育独特的郑厂长酱酒味道——幽雅细腻、酒体丰满醇厚、回味悠长。</p>
      <h2>历史文化</h2>
      <p>郑厂长品牌中的郑厂长指的就是茅台初代老厂长、“国酒大师”郑义兴，为纪念郑义兴为中国白酒做出的杰出贡献而成立，旨在传承郑义兴百年酱酒秘方，复原茅香本味。</p>
      <p>郑义兴，出生于光绪二十一年，建国前便是茅台镇著名的成义、荣和、恒兴几大私人烧坊不惜重金争夺的对象，更是当时郑氏家族“四大酒师”中的佼佼者；</p>
      <p>1953年，地方国营茅台酒厂成立，郑义兴进入茅台酒厂工作；</p>
      <p>1951至1953年期间，茅台酒厂开展以增产节约为中心的社会主义劳动竞赛，提出了“沙子（即茅台酒原料红高粱）磨细点，一年四季都可以产酒”的决定。郑义兴指出“这样进行生产，违背了茅台酒生产的规律，只能生产出普通的高粱酒。”但郑义兴的意见没有引起厂领导的重视，导致茅台酒的质量开始下滑。</p>
      <p>1956年，茅台酒厂开始以提高产品质量为中心的运动，采纳了郑义兴恢复传统工艺操作的建议。茅台基酒合格率由1956年的12.19%，提高到1958年的99.42%。郑义兴将家族五代传承技艺与自己30余年酿酒经验贡献出来，编写《茅台酒操作规程》，并担任茅台副厂长。郑义兴总结了酱香型酒的勾兑方法，后成为第一代贵州茅台酒酿造的核心力量。</p>
      <p>1978年，郑义兴在茅台镇逝世。</p>
      <p>1997年，为纪念其在白酒行业的重要贡献，贵州茅台酒厂在国酒文化城内为其塑像，尊其为“国酒大师”。</p>
      <p>郑厂长酱酒沿袭郑义兴家族百年世代传承的酿酒秘方，以郑义兴头像为产品图案，旨在纪念郑义兴，用郑义兴秘方、高标准工艺，复原茅香本味。</p>
    </div>
    <div id="zyx" v-if="zyx" class="encyclopedia-list">
      <h1>怀国酒业</h1>
      <p>怀国酒业秉承健康人生理念，复原原茅台副厂长、国酒大师郑义兴酿酒秘方，集结多位大师的酿造智慧，联合中国白酒研究中心、贵州陈酿酱香白酒研究院，甄选方案，精心酿造。</p>
      <img src="~@/assets/encyclopedia/贵州怀国酒业（集团）有限公司.png" alt="贵州怀国酒业（集团）有限公司" style="width: 600px"/>
    </div>
    <div id="huaiguo3" v-if="huaiguo3" class="encyclopedia-list">
      <h1>贵州怀国酒业（集团）有限公司</h1>
      <p>义项名：怀国酒企业</p>
      <p>贵州怀国酒业(集团)有限公司位于中国酱酒核心产区茅台镇，海拔高度450米，占地300余亩，生产基地离赤水河仅30多米；建有4大生产及包装厂区，建有大曲酱香酒窖池200余口；拥有一套完整的质量管理体系和多条机械化生产线。年生产优质酱香基酒5000多吨，储酒能力达1万多吨，是茅台镇酱酒核心产区的大型酿酒企业。</p>
      <p>2022-2023 荣获白酒行业领军品牌金谱奖、CCTV“国货之光”巡展品牌</p>
      <p>2023-2024 利比里亚共和国驻华大使馆指定用酒</p>
      <p>2024年    泰国皇室指定用酒</p>
      <h2>【信息栏】</h2>
      <p>公司名称：贵州怀国酒业(集团)有限公司</p>
      <p>生产地址：贵州省怀仁市茅台镇</p>
      <p>经营范围：酒类经营</p>
      <p>产品系列：怀国酱酒，高端酒，定制酒</p>
      <h2>【发展历程】</h2>
      <p>1908年，成立私人老烧坊</p>
      <p>1935年，初创中草药制曲秘方</p>
      <p>1983年，复原郑义兴秘方，结合郑义兴中草药制曲法，形成117味中草药草本秘方，并全面恢复生产</p>
      <p>2008年，怀国品牌管理有限公司成立，怀国酱酒畅销全国</p>
      <p>2018年，成立电商事业部</p>
      <p>2020年，加速线下门店酒友汇布局</p>
      <p>2021年，推出怀国定制酒，精细化产品线运营</p>
      <p>2022年，成立怀国酒业集团</p>
      <p>2023年，成立全球事业部，布局海外市场</p>
      <p>2024年，与中国白酒研究中心、贵州陈酿酱香白酒研究院签约合作</p>
      <div style="width: 600px">
        <img src="~@/assets/encyclopedia/酒厂图1.png" alt="贵州怀国酒厂" style="width: 200px"/>
        <img src="~@/assets/encyclopedia/酒厂图2.png" alt="贵州怀国酒厂" style="width: 200px"/>
        <img src="~@/assets/encyclopedia/酒厂图3.png" alt="贵州怀国酒厂" style="width: 200px"/>
      </div>
      <br>
      <h2>【企业文化】</h2>
      <p><strong>企业使命：</strong>秉承中华文化，让世界喝到健康中国酒</p>
      <p><strong>企业愿景：</strong>中国健康白酒行业的领军品牌  世界一流的健康白酒品牌</p>
      <p><strong>价值观：</strong>义为先，诚心经营良心酿酒</p>
      <p style="text-indent: 6em;">质为道，健康饮酒健康人生</p>
      <p><strong>企业战略：</strong>深耕国内，千城万店服务千万酒友</p>
      <p style="text-indent: 6em;">布局海外，让怀国成为全球性酱酒品牌</p>
      <p><strong>企业理念：</strong></p>
      <p><strong>员工：</strong>见行动，以德为先，德才兼备；</p>
      <p><strong>客户：</strong>践服务，品质至上，诚信经营；</p>
      <p><strong>社会：</strong>鉴未来，健康饮酒，健康人生；</p>
      <p><strong>同行：</strong>建共赢，和而不同，良性竞争。</p>
      <h2>【品牌荣誉】</h2>
      <p>怀国酒业先后荣获2022-2023白酒行业领军品牌金谱奖、利比里亚共和国驻华大使馆指定用酒、CCTV“国货之光”巡展品牌、贵州酒文化研究院副会长单位、十周年国礼品牌、入选品牌强国优选工程、白酒领域3A级信用企业、质量服务信誉3A级示范单位等。</p>
      <p>2020年，腾讯社交广告优秀合作伙伴</p>
      <p>2022年，中央电视台《国货之光》节目展播企业</p>
      <p>2022年，中国品牌节金谱奖，白酒行业领军品牌</p>
      <p>2023年，利比里亚共和国驻华大使馆指定用酒</p>
      <p>2024年，泰国皇室御用酒</p>
      <img src="~@/assets/encyclopedia/贵州怀国酒业品牌荣誉.png" alt="贵州怀国酒业品牌荣誉" style="width: 600px"/>
      <br>
      <br>
      <h2>【社会责任】</h2>
      <p>2023年8月，涿州赈灾物资捐赠</p>
      <p>怀国酒业第一时间成立物资采购小组支援涿州，助力保障受灾群众的食品安全。</p>
      <p>2023年12月，甘肃赈灾物资捐赠</p>
      <p>怀国酒业联合红十字会向甘肃地震灾区捐赠首批救灾物资，用于支援甘肃震区的紧急救援、受灾群众生活安置等。</p>
      <p>2024年1月，发起崀山爱心助农行动</p>
      <p>怀国酒业发起爱心助农福利行动，以买代帮，践行公益，践行企业社会责任，助力乡村振兴。</p>
      <img src="~@/assets/encyclopedia/贵州怀国社会责任.png" alt="贵州怀国社会责任" style="width: 600px"/>
      <br>
      <br>
      <h2>【产品实力】</h2>
      <p>怀国酒秉承健康人生理念，复原原茅台副厂长、国酒大师郑义兴酿酒秘方，集结多位大师的酿造智慧，联合中国白酒研究中心、贵州陈酿酱香白酒研究院，甄选最优方案，精心酿造。产品以茅台镇本地特制红缨子糯高粱、小麦、赤水河河水为原料， 百年传承古法结合12987工艺匠心纯粮酿制。怀国酱酒纯粮系 列酱酒，极具酱香突出、幽雅细腻、酒体醇厚、回味悠长、空杯留香持久的独特风格，产品远销国外。</p>
      <div style="width: 600px">
        <img src="~@/assets/encyclopedia/贵州怀国产品研究1.png" alt="贵州怀国产品研究" style="width: 380px"/>
        <img src="~@/assets/encyclopedia/贵州怀国产品研究2.png" alt="贵州怀国产品研究" style="width: 200px"/>
      </div>
      <br>
      <br>
      <h2>怀国酒业</h2>
      <p>怀国酒业秉承健康人生理念，复原原茅台副厂长、国酒大师郑义兴酿酒秘方，集结多位大师的酿造智慧，联合中国白酒研究中心、贵州陈酿酱香白酒研究院，甄选方案，精心酿造。</p>
      <br>
      <br>
      <h2>郑义兴 （1895—1978）</h2>
      <p>原茅台副厂长、技术顾问、国酒大师郑义兴秘方</p>
      <p>“国酒大师”茅台老厂长郑义兴，中国著名酿酒师，是第一代贵州茅台酒 酿造的核心力量，现代酱香型白酒鼻祖，最初“飞天”牌茅台的创意来源，传授了李兴发等一代又一代酿酒大师。茅台酒厂在国酒文化城内为其塑像排位第一并尊为“国酒大师”。 1983年，怀国前身私人烧坊结合“国酒大师”茅台老厂长郑义兴家传百年 中草药制曲法， 复原其独特配方， 再次改良制曲工艺， 终于形成117味中草药制曲秘方，并全面恢复生产。</p>
      <img src="~@/assets/encyclopedia/贵州怀国酒业（集团）有限公司.png" alt="贵州怀国酒业（集团）有限公司" style="width: 600px"/>
      <br>
      <br>
      <h2>【核心业态】</h2>
      <p>定制酒  私域酒友汇  海外营销   怀国酒厂  线下门店</p>
      <div style="width: 600px">
        <img src="~@/assets/encyclopedia/贵州怀国业态1.png" alt="贵州怀国业态" style="width: 300px"/>
        <img src="~@/assets/encyclopedia/贵州怀国业态2.png" alt="贵州怀国业态" style="width: 300px"/>
      </div>
      <div style="width: 600px">
        <img src="~@/assets/encyclopedia/贵州怀国业态3.png" alt="贵州怀国业态" style="width: 300px"/>
        <img src="~@/assets/encyclopedia/贵州怀国业态4.png" alt="贵州怀国业态" style="width: 300px"/>
      </div>
      <div style="width: 600px">
        <img src="~@/assets/encyclopedia/贵州怀国业态5.png" alt="贵州怀国业态" style="width: 300px"/>
        <img src="~@/assets/encyclopedia/贵州怀国业态6.png" alt="贵州怀国业态" style="width: 300px"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EncyclopediaPage",
  data() {
    return {
      huaiguo: false,
      huaiguo2: false,
      zunxie: false,
      zcz: false,
      zyx: false,
      huaiguo3: false,
    };
  },
  mounted() {
    this.updateEnvironmentBasedOnHash();
  },
  watch: {
    '$route': function () {
      this.updateEnvironmentBasedOnHash();
    }
  },
  methods: {
    updateEnvironmentBasedOnHash() {
      if (this.$route.hash === '#huaiguo') {
        this.huaiguo = true;
        this.huaiguo2 = false;
        this.zunxie = false;
        this.zcz = false;
        this.zyx = false;
        this.huaiguo3 = false;
      } else if (this.$route.hash === '#huaiguo2') {
        this.huaiguo = false;
        this.huaiguo2 = true;
        this.zunxie = false;
        this.zcz = false;
        this.zyx = false;
        this.huaiguo3 = false;
      }else if (this.$route.hash === '#zunxie') {
        this.huaiguo = false;
        this.huaiguo2 = false;
        this.zunxie = true;
        this.zcz = false;
        this.zyx = false;
        this.huaiguo3 = false;
      }else if (this.$route.hash === '#zcz') {
        this.huaiguo = false;
        this.huaiguo2 = false;
        this.zunxie = false;
        this.zcz = true;
        this.zyx = false;
        this.huaiguo3 = false;
      }else if (this.$route.hash === '#zyx') {
        this.huaiguo = false;
        this.huaiguo2 = false;
        this.zunxie = false;
        this.zcz = false;
        this.zyx = true;
        this.huaiguo3 = false;
      }else if (this.$route.hash === '#huaiguo3') {
        this.huaiguo = false;
        this.huaiguo2 = false;
        this.zunxie = false;
        this.zcz = false;
        this.zyx = false;
        this.huaiguo3 = true;
      }

    },
    toggleEnvironment(type) {
      this.huaiguo = (type === 'huaiguo');
    },
  }
}
</script>

<style scoped>
/* 页面基础样式 */
.encyclopedia-page {
  text-align: center;
  font-family: 'Arial', sans-serif; /* 使用合适的字体 */
  background-image: url('~@/assets/product/product-background.jpg');
}

/* 导航栏样式 */
.navbar {
  position: fixed; /* 固定导航栏 */
  top: 106px; /* 导航栏距离页面顶部的距离 */
  left: 0; /* 导航栏距离页面左边的距离 */
  width: 100%; /* 导航栏宽度 */
  z-index: 1; /* 确保导航栏在其他内容之上 */
  background-color: white; /* 背景色 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 可选：添加阴影效果 */

  height: 50px;
  padding: 15px 0px;
  display: flex;
  justify-content: center;
  background-color: white;
}


.navbar .nav-link {
  margin: 0 40px; /* 增加左右间距 */
  text-decoration: none;
  color: #333;
  cursor: pointer;
  font-size: 20px; /* 字体大小 */
}

/* 当悬浮在链接上时的样式 */
.navbar .nav-link:hover {
  color: #1c61ae;
}

.navbar .nav-link.active {
  color: #1c61ae; /* 活跃链接的颜色 */
}

.encyclopedia-list {
  max-width: 600px;
  padding: 80px 5px 0px 5px;
  margin-left: auto;    /* 左边距自动 */
  margin-right: auto;   /* 右边距自动 */
}

.encyclopedia-list h1{
  font-size: 25px;
}


.encyclopedia-list h2,
.encyclopedia-list h3 {
  text-align: left;     /* 标题靠左对齐 */
}

/* 设置正文样式 */
.encyclopedia-list p {
  text-align: justify; /* 设置文本对齐为两端对齐 */
  text-indent: 2em; /* 设置首行缩进 */
  line-height: 1.6; /* 设置行高 */
  margin-bottom: 1em; /* 设置段落之间的距离 */
}

@media screen and (max-width: 768px) {
  /* 导航栏样式调整 */
  .navbar {
    padding: 10px 0;
  }

  .navbar .nav-link {
    margin: 10px 0;
    font-size: 12px;
    padding-right: 10px;
  }

}


</style>
