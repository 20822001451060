<template>
  <div id="newsPage" class="news-page">
    <!-- 新闻页头部 -->
    <div class="news-header">
      公司新闻
    </div>
    <hr class="title-divider">
    <!-- 新闻列表 -->
    <div class="news-list">
      <div
          class="news-item"
          v-for="(item, index) in newsArray"
          :key="index"
      >
        <a :href="item.link" target="_blank" class="news-image-wrapper">
          <img :src="item.imageUrl" :alt="item.title" class="news-image">
        </a>
        <div class="news-content">
          <!-- 新闻标题作为链接 -->
          <h2 class="news-title">
            <a :href="item.link" target="_blank">{{ item.title }}</a>
          </h2>
          <p class="news-text">{{ item.detail }}</p>
          <span class="news-date">{{ item.date }}</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'NewsPage',
  components:{
  },
  data() {
    return {
      // 新闻内容数组示例，包括链接
      newsArray: [
        {
          title: '怀国酒业蝉联白酒行业领军品牌金谱奖',
          detail:'近日，2023第十七届中国品牌节在杭州开幕，6000余家全国知名品牌齐聚一堂，共赴品牌盛宴。贵州怀国酒业集团有限公司荣获2023中国白酒行业领军品牌金谱奖，这也是怀国酒业第二次获此奖项殊荣。',
          imageUrl: require('@/assets/news/news1.jpg'),
          link: 'https://mp.weixin.qq.com/s?__biz=Mzk0MzQyMzYwMA==&mid=2247484952&idx=1&sn=2b143c2f52b6501569dde7ff58eb244f&chksm=c3356f07f442e6119df1bebfdaae9f4f6d8086176d0520b9c7780a5e6873db831325907b831a&token=272914640&lang=zh_CN#rd',
          date: '2023-11-18'
        },
        {
          title: '贵州怀国酒业集团当选第三届“贵州省酒文化研究会副会长单位”',
          detail:'近日，贵州省酒文化研究会第三届理事会、第三届会员代表大会暨换届大会在贵阳顺利召开，贵重怀国酒业集团荣幸当选“贵州省酒文化研究会副会长单位”。',
          imageUrl: require('@/assets/news/news2.jpg'),
          link: 'http://www.gywb.cn/system/2023/10/07/084433615.shtml',
          date: '2023-10-07'
        },
        {
          title: '喜讯 | 贵州怀国酒业入选“十周年·国礼品牌”！',
          detail:'9月26日，由上合组织元首峰会协办单位——丝路文化之旅组委会等机构共同发起的“十周年•丝路文化之旅”，贵州怀国酒业集团荣膺“十周年•国礼品牌”殊荣，以民族品牌身份，将国礼品质和中国白酒文化传递至全世界。',
          imageUrl: require('@/assets/news/news3.jpg'),
          link: 'https://mp.weixin.qq.com/s?__biz=Mzk0MzQyMzYwMA==&mid=2247484874&idx=1&sn=89bde00b59979de06c9ad04b75dd381c&chksm=c3356cd5f442e5c3b096bca51ee680746d0292c19089c1f1bb96562d19928eefc437b6b7ea87&token=1434640147&lang=zh_CN#rd',
          date: '2023-09-26'
        },
        {
          title: '对话潘基文，贵州怀国酒业集团受邀参加第六届华夏谷论坛',
          detail:'9月9日-11日，以“承载时代使命·引领商业文明”为主题的第六届华夏谷全球社会企业家生态论坛在北京举行。联合国前任秘书长潘基文、著名投资家吉姆·罗杰斯及来自全国的杰出企业家齐聚一堂，共话生态未来，贵州怀国酒业集团受邀参会。',
          imageUrl: require('@/assets/news/news4.jpg'),
          link: 'https://china.qianlong.com/2023/0914/8110250.shtml',
          date: '2023-09-14'
        },
        {
          title: '怀国酒业 | 以酒会友，架起中外友谊桥梁！',
          detail:'8月26日，2023世界领袖交流会在长沙举行。现场大咖云集，来自意大利、毛里求斯、克罗地亚等多国前政要，国内知名科学家、优秀企业家代表出席。怀国酒业集团作为特邀百年传承优秀酒企也在受邀之列，此外，怀国酒也是本次国际公益晚宴高端招待白酒。',
          imageUrl: require('@/assets/news/news5.jpg'),
          link: 'https://www.sohu.com/a/716445781_120001928',
          date: '2023-08-26'
        },
      ]
    };
  },
  mounted() {
    if (this.$route.hash) {
      const element = document.getElementById(this.$route.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        const rect = element.getBoundingClientRect();
        const top = rect.top + window.scrollY - 100;
        window.scrollTo({
          top: top,
          behavior: 'smooth'
        });
      }
    }
  },
};
</script>

<style scoped>
.news-page {
  /* 为新闻页面容器设置样式 */
  background-image: url('~@/assets/product/product-background.jpg');
  border-bottom: 1px solid #f0f0f0;
}

.title-divider {
  margin: 10px auto 40px auto;
  border: 0; /* 移除默认边框 */
  height: 2px; /* 线条高度 */
  background-color: #333; /* 线条颜色，可根据设计调整 */
  width: 3%; /* 线条宽度，可根据设计调整 */
}

.news-header {
  text-align: center; /* 标题居中 */
  padding: 40px 0px 10px 0px; /* 添加一些下边距 */
  font-size: 32px; /* 字体大小调整 */
  /* 字体可根据实际情况调整，这里只是示例 */
}

.news-list {
  padding: 0 200px;
}

.news-item {
  background-color: #f1f3f5;
  display: flex;
  align-items: center; /* 新增，垂直居中对齐 */
  margin-bottom: 30px;
  border-radius: 10px; /* 给整个新闻项添加圆角 */
  overflow: hidden; /* 隐藏溢出内容以确保圆角生效 */
  background-color: white;
}


.news-item:hover {
  /* 鼠标悬浮时新闻栏的背景颜色变为白色 */
  background-color: white;
}
.news-item:hover .news-image {
  /* 鼠标悬浮时图片放大 */
  transform: scale(1.1);
}

.news-item:hover .news-title a {
  /* 鼠标悬浮时标题变色 */
  color: #1c61ae;
}

.news-image-wrapper {
  width: 500px; /* 图片容器宽度，和图片一致 */
  overflow: hidden; /* 隐藏溢出的内容，使border-radius生效 */
  border-radius: 10px; /* 图片四角圆角 */
  line-height: 0; /* 移除a标签内部的额外高度 */
}

.news-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease; /* 添加动画效果 */
  border-top-left-radius: 10px; /* 图片左上角圆角 */
  border-bottom-left-radius: 10px; /* 图片左下角圆角 */
}


.news-content {
  padding: 20px; /* 内容的内边距，确保足够的空间给日期 */
  width: 100%; /* 使内容占据剩余空间 */
  position: relative; /* 相对定位，为日期定位做准备 */
  border-radius: 10px; /* 给内容的右上角添加圆角 */
  /* 确保边框大小足够容纳日期 */
}

.news-title {
  text-align: left; /* 标题居中显示 */
  margin: 0; /* 新增，移除默认的外边距 */
}

.news-title a {
  color: #000;
  text-decoration: none;
}

.news-text{
  padding-top: 30px;
}

.news-date {
  position: absolute; /* 绝对定位 */
  font-size: 18px;
  bottom: -20px; /* 距离底部的位置，可根据需要调整 */
  right: 20px; /* 距离右侧的位置，可根据需要调整 */
  color: #666;
}


/* 媒体查询，适用于小于768px的屏幕（如智能手机） */
@media (max-width: 768px) {
  .news-list {
    padding: 0 15px; /* 缩小新闻列表的内边距 */
  }

  .title-divider {
    width: 10%; /* 线条宽度，可根据设计调整 */
  }

  .news-item {
    flex-direction: column; /* 将新闻项堆叠垂直显示 */
    margin-bottom: 20px; /* 减小新闻项的底部边距 */
    height: 430px;
  }

  .news-image-wrapper {
    width: 100%; /* 图片容器占满整个宽度 */
  }

  .news-content {
    padding: 15px; /* 调整内容的内边距 */
  }

  .news-title {
    font-size: 16px; /* 减小标题字体大小 */
  }

  .news-date {
    bottom: -10px; /* 调整日期位置 */
    right: 10px;
  }
}

</style>


