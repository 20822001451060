<template>
  <div>
    <swiper
        class="swiper"
        :slidesPerView="1"
        :spaceBetween="30"
        :loop="true"
        :autoplay="{delay: 3500, disableOnInteraction: false}"
        :pagination="{el: '.swiper-pagination',clickable: true}"
        :navigation="{nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'}"
        :modules="modules"
    >
      <swiper-slide v-for="(image, index) in images" :key="index">
        <img class="carouselImg" :src="image" alt="贵州怀国酒业">
      </swiper-slide>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </swiper>
  </div>

</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Autoplay,Navigation, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

export default {
  name: 'HomeCarouselPage',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {},
  // 这里用于存放数据
  data() {
    return {
      images: [
        require('@/assets/home/carousel1.jpg'),
        require('@/assets/home/carousel2.jpg'),
        require('@/assets/home/carousel3.jpg')
      ]
    }
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Autoplay,Navigation, Pagination],
    };
  },
}
</script>

<style scoped>

.swiper{
  width: 100%;
  height: 88vh;
}

.swiper-button-prev, .swiper-button-next {
  color: #ccc; /* 更改颜色 */
  width: 50px; /* 更改宽度 */
  height: 50px; /* 更改高度 */
  margin: 10px;
}

.swiper-button-prev:hover, .swiper-button-next:hover {
  color: #1c61ae; /* 悬停状态下的颜色 */
}

:deep(.swiper-pagination-bullet) {
  background: #fff;
  width: 10px;
  height: 10px;
  opacity: 1;
}

:deep(.swiper-pagination-bullet-active) {
  background: #1c61ae;
}

.carouselImg {
   height: 100%; /* 图片高度固定为视窗高度的 88% */
   width: 100%; /* 图片宽度适应容器宽度 */
   object-fit: cover; /* 图像覆盖整个区域，如果不想裁剪图片，可以使用 contain */
 }

@media (max-width: 768px) {
  .swiper{
    width: 100%;
    height: auto;
  }
  .swiper-button-prev, .swiper-button-next{
    display: none;
  }

}
</style>
