<template>
  <home-page></home-page>
</template>

<script>
import HomePage from '@/components/HomePage'

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>
