import { createApp, h } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/reset.css';
import HomeBodyPage from '@/components/home/HomeBodyPage.vue';
import IntroductionPage from '@/components/introduction/IntroductionPage.vue';
import NewsPage from '@/components/news/NewsPage.vue';
import ProductPage from '@/components/product/ProductPage.vue';
import FactoryEnvironment from '@/components/environment/FactoryEnvironment.vue';
import JoinPage from '@/components/join/JoinPage.vue';
import ServiceCenter from '@/components/service/ServiceCenter.vue';
import ContactUs from '@/components/contact/ContactUs.vue';
import EncyclopediaPage from '@/components/encyclopedia/EncyclopediaPage.vue';

const routes = [
    { path: '/', component: HomeBodyPage }, // 默认路由
    { path: '/introduction', component: IntroductionPage, name: 'introduction'  }, // 公司介绍路由
    { path: '/news', component: NewsPage, name: 'news'  }, // 新闻页面
    { path: '/productPage', component: ProductPage, name: 'productPage'  }, // 产品页面
    { path: '/serviceCenter', component: ServiceCenter, name: 'serviceCenter'  }, // 服务页面
    { path: '/factoryEnvironment', component: FactoryEnvironment, name: 'factoryEnvironment'  }, // 厂区环境
    { path: '/joinPage', component: JoinPage, name: 'joinPage'  }, // 招商加盟
    { path: '/contactUs', component: ContactUs, name: 'contactUs'  }, // 联系我们
    { path: '/encyclopedia', component: EncyclopediaPage, name: 'encyclopedia'  }, // 联系我们
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeEach((to, from, next) => {
    // 设置页面标题为您想要的标题
    document.title = to.meta.title || "贵州怀国酒业（集团）有限公司官网";
    next();
});

router.afterEach((to) => {
    if (to.hash) {
        const element = document.getElementById(to.hash.slice(1));
        if (element) {
            // 获取元素的位置
            const rect = element.getBoundingClientRect();
            // 计算相对于视口的顶部位置，并减去100px
            const top = rect.top + window.scrollY - 100;

            // 使用window.scrollTo滚动到新的位置
            window.scrollTo({
                top: top, // 新的y坐标
                behavior: 'smooth'
            });
        }
    }
});

createApp({
    render: () => h(App),
})
    .use(Antd)
    .use(router)
    .mount('#app');
