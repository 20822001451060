<template>
  <div class="form-container">
    <h1>{{ title }}</h1>
    <form @submit.prevent="submitForm">
      <div class="input-group">
        <label>姓名</label>
        <input type="text" v-model="form.cusName" />
        <span v-if="nameError" class="error-message">{{ nameError }}</span>
      </div>
      <div class="input-group">
        <label>电话</label>
        <input type="tel" v-model="form.cusPhone" />
        <span v-if="phoneError" class="error-message">{{ phoneError }}</span>
      </div>
      <div class="input-group">
        <label>{{ contentLabel }}</label>
        <textarea v-model="form.cusNotes"></textarea>
        <span v-if="contentError" class="error-message">{{ contentError }}</span>
      </div>
      <div class="submit-group">
        <button type="submit" :disabled="isSubmitting">{{ submitButtonText }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { reactive, ref,toRefs } from 'vue';

export default {
  name: 'ContactForm',
  props: {
    title: String,
    contentLabel: {
      type: String,
      default: '留言'
    },
    submitButtonText: {
      type: String,
      default: '立即联系'
    },
    onSubmit: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const { onSubmit } = toRefs(props);  // 使用 toRefs 来保持响应性

    const form = reactive({
      cusName: '',
      cusPhone: '',
      cusNotes: '',
      notesType:'2',
      sysOrgCode:'A01'
    });
    const nameError = ref('');
    const phoneError = ref('');
    const contentError = ref('');
    const isSubmitting = ref(false);

    const validateForm = () => {
      nameError.value = form.cusName ? '' : '请填写姓名';
      phoneError.value = form.cusPhone.match(/^\d{11}$/) ? '' : '电话号码必须为11位数字';
      contentError.value = form.cusNotes ? '' : '请填写留言';
    };

    const submitForm = () => {
      validateForm();
      if (nameError.value || phoneError.value || contentError.value) {
        return;
      }
      isSubmitting.value = true;
      // 调用父组件传递的提交方法
      onSubmit.value(form, () => {
        isSubmitting.value = false; // 在回调函数中重置提交状态
        form.cusName = '';
        form.cusPhone = '';
        form.cusNotes = '';
      });
    };

    return { form, nameError, phoneError, contentError, isSubmitting, submitForm };
  }
}
</script>

<style scoped>
.form-container {
  width: 450px;
  margin: 80px auto;
  padding: 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.form-container h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 30px;
}

.input-group {
  margin-bottom: 10px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 20px;
}
.error-message {
  color: red;
  font-size: 0.8em;
}

.input-group input,
.input-group textarea {
  background-color: transparent;
  width: 100%;
  padding: 8px;
  border: none; /* 移除所有边框 */
  border-bottom: 1px solid #ccc; /* 仅添加下边框 */
  border-radius: 0; /* 移除圆角 */
}

.submit-group {
  margin-top: 20px;
}

.submit-group button {
  width: 330px;
  font-size: 20px;
  padding: 10px 15px;
  border: none;
  background-color: #bdc3c7;
  color: black;
  cursor: pointer;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {

  /* 表单容器和其他相关元素的样式也可以相应调整 */
  .form-container {
    width: 100%; /* 调整宽度为100% */
    margin: 0px;
  }

  .input-group input,
  .input-group textarea {
    font-size: 14px; /* 减小输入框字体大小 */
  }

  .submit-group button {
    width: 220px;
    padding: 8px 10px; /* 减小按钮内边距 */
  }
}

</style>
