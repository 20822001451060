<template>
  <div class="window-news">
    <div class="title-box">
      <div class="title-font">
        <strong>企业新闻</strong>
        <hr class="title-divider">
      </div>
    </div>
    <div class="news-box">
      <div class="show-more-btn"><router-link to="/news">查看更多 &gt;</router-link></div>
      <div class="swiper-container" style="cursor: grab;">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in news" :key="item.id">
            <div class="img-box"
                 :style="{'background-image': `url(${item.image})`}">
            </div>
            <div class="content-box">
              <div class="news-title">{{ item.title }}</div>
              <div class="news-date">{{ item.date }}</div>
              <div class="content-text">
                <p>{{ item.content }}</p>
              </div>
              <a :href="item.link" target="_blank" class="more-btn">了解更多</a>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "HomeNews",
  data() {
    return {
      // 新闻数据数组
      news: [
        {
          id: 1,
          image: require('@/assets/home/news/1.jpg'),
          title: '贵州怀国酒业集团当选第三届“贵州省酒文化研究会副会长单位”',
          date: '2023-10-07',
          content: '近日，贵州省酒文化研究会第三届理事会、第三届会员代表大会暨换届大会在贵阳顺利召开，贵重怀国酒业集团荣幸当选“贵州省酒文化研究会副会长单位”。',
          link: 'http://www.gywb.cn/system/2023/10/07/084433615.shtml'
        },
        {
          id: 2,
          image: require('@/assets/home/news/2.jpg'),
          title: '喜讯 | 贵州怀国酒业入选“十周年·国礼品牌”！',
          date: '2023-09-15',
          content: '9月26日，由上合组织元首峰会协办单位——丝路文化之旅组委会等机构共同发起的“十周年•丝路文化之旅”，贵州怀国酒业集团荣膺“十周年•国礼品牌”殊荣，以民族品牌身份，将国礼品质和中国白酒文化传递至全世界。',
          link: 'https://mp.weixin.qq.com/s?__biz=Mzk0MzQyMzYwMA==&mid=2247484874&idx=1&sn=89bde00b59979de06c9ad04b75dd381c'
        },
        {
          id: 3,
          image: require('@/assets/home/news/3.jpg'),
          title: '对话潘基文，贵州怀国酒业集团受邀参加第六届华夏谷论坛',
          date: '2023-09-14',
          content: '9月9日-11日，以“承载时代使命·引领商业文明”为主题的第六届华夏谷全球社会企业家生态论坛在北京举行。联合国前任秘书长潘基文、著名投资家吉姆·罗杰斯及来自全国的杰出企业家齐聚一堂，共话生态未来，贵州怀国酒业集团受邀参会。',
          link: 'https://china.qianlong.com/2023/0914/8110250.shtml'
        }
      ]
    };
  }
}
</script>

<style scoped>

.window-news {
  background-image: url('~@/assets/home/backgroundImag.jpg');
  width: 100%;
  height: 88vh;
}

.title-box {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 50px
}

.title-font {
  display: block;
  font-size: 32px;
  text-align: center;
  font-family: SourceHanSansCNBold;
}

.title-divider {
  margin-top: 20px; /* 在标题下方留出20px的空间 */
  border: 0; /* 移除默认边框 */
  height: 2px; /* 线条高度 */
  background-color: #333; /* 线条颜色，可根据设计调整 */
  width: 3%; /* 线条宽度，可根据设计调整 */
  margin-left: auto; /* 水平居中 */
  margin-right: auto; /* 水平居中 */
}

.news-box {
  font-family: SourceHanSansCNBold;
  margin: 10px auto 0;
  overflow: hidden;
  padding: 50px 50px 0;
  position: relative;
  box-sizing: border-box;
}

.news-box .show-more-btn {
  position: absolute;
  right: 230px;
  top: 20px;
  z-index: 0;
  cursor: pointer;
}

.news-box .swiper-slide {
  width: 400px;
  font-size: 18px;
  font-family: SourceHanSansCNBold;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  margin: 20px;
}
.news-box .swiper-slide .img-box {
  width: 100%;
  height: 220px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.news-box .swiper-slide .content-box {
  padding-top: 20px;
  display: flex; /* 使用flex布局 */
  flex-direction: column; /* 列方向布局 */
}

/* 新增样式 - 新闻标题 */
.news-box .swiper-slide .news-title {
  font-size: 18px; /* 标题字体大小 */
  margin-bottom: 10px; /* 与下方内容的间距 */
}

/* 新增样式 - 新闻日期 */
.news-box .swiper-slide .news-date {
  font-size: 14px; /* 日期字体大小 */
  color: #666; /* 字体颜色 */
  margin-bottom: 20px; /* 与下方内容的间距 */
}

 .news-box .swiper-slide .content-text {
   font-size: 14px; /* 日期字体大小 */
   color: #666; /* 字体颜色 */
   margin-bottom: 20px; /* 与下方内容的间距 */
 }

/* 新增样式 - “了解更多”按钮 */
.news-box .swiper-slide .more-btn {
  width: 150px;
  font-size: 14px;
  padding: 10px 15px; /* 按钮内边距 */
  background-color: white; /* 按钮背景色 */
  color: black; /* 字体颜色 */
  text-align: center; /* 文字居中 */
  border-radius: 5px; /* 按钮圆角 */
  cursor: pointer; /* 鼠标样式 */
  margin-top: auto; /* 置于底部 */
  border: 1px solid black; /* 添加黑色边框 */
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: #1c61ae;
}

@media screen and (max-width: 768px) {
  .window-news {
    height: auto;
    background-size: cover;
  }

  .title-box {
    padding-top: 20px;
  }

  .title-font {
    font-size: 24px;
  }

  .title-divider {
    width: 10%;
    margin-top: 10px;
  }

  .news-box {
    padding: 20px;
  }

  .news-box .show-more-btn {
    right: 20px;
    top: 10px;
  }

  .news-box .swiper-slide {
    width: 100%;
    margin: 10px 0;
  }

  .news-box .swiper-slide .img-box {
    height: 200px;
  }

  .news-box .swiper-slide .content-box {
    padding-top: 10px;
  }

  .news-box .swiper-slide .news-title {
    font-size: 16px;
  }

  .news-box .swiper-slide .news-date {
    font-size: 12px;
  }

  .news-box .swiper-slide .content-text {
    font-size: 12px;
  }

  .news-box .swiper-slide .more-btn {
    font-size: 12px;
    padding: 8px 12px;
    width: 120px;
  }

  .swiper-wrapper {
    flex-direction: column;
  }
}

</style>
