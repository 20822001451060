<template>
  <div class="product-page">
    <!-- 导航栏 -->
    <div class="navbar">
      <router-link class="nav-link" v-for="category in categories" :key="category"
                   :to="{ name: 'productPage', hash: '#' + category }">
        {{ category }}
      </router-link>
    </div>
    <!-- 产品列表 -->
    <div class="product-list" id="臻品系列">
      <div class="category-text">臻品系列</div>
      <div class="products">
        <swiper
            :slidesPerView="getSlidesPerView(5)"
            :spaceBetween="20"
            :freeMode="true"
            :loop="true"
            :pagination="{clickable: true}"
            :autoplay="{delay: 1500, disableOnInteraction: false}"
            :modules="modules"
            class="product-item"
            id="premium-series"
        >
          <swiper-slide
              v-for="product in getProductsByCategory('臻品系列')"
              :key="product.id"
              @click="showProductDetails(product)"
          >
            <img :src="product.image" :alt="product.name" class="product-img">
            <div class="product-list-name">
              <h3>{{ product.name }}</h3>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <div class="product-list" id="定制系列">
      <div class="category-text">定制系列</div>
      <div class="products">
        <swiper
            :slidesPerView="getSlidesPerView(4)"
            :spaceBetween="20"
            :freeMode="true"
            :loop="true"
            :pagination="{clickable: true}"
            :autoplay="{delay: 1500, disableOnInteraction: false}"
            :modules="modules"
            class="product-item"
            id="made-series"
        >
          <swiper-slide
              v-for="product in getProductsByCategory('定制系列')"
              :key="product.id"
          >
            <img :src="product.image" :alt="product.name" class="product-img">
            <div class="product-list-name">
              <h3>{{ product.name }}</h3>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <!-- 产品详情弹出框 -->
    <div v-if="selectedProduct" class="product-details-modal" @click.self="selectedProduct = null">
      <div class="product-details-content">
        <button class="close-button" @click="selectedProduct = null">X</button>
        <div class="product-details-body">
          <img :src="selectedProduct.image" :alt="selectedProduct.name" class="product-details-image">
          <div class="product-details-text">
            <div class="product-name">{{ selectedProduct.name }}</div>
            <hr class="divider">
            <div class="product-info-div">
              <span class="product-info"><strong>香型：</strong>{{ selectedProduct.series }}</span>
              <span class="product-info"><strong>酒精度：</strong>{{ selectedProduct.alcoholContent }}</span>
              <span class="product-info"><strong>净含量：</strong>{{ selectedProduct.volume }}</span>
              <span class="product-info"><strong>产地：</strong>{{ selectedProduct.origin }}</span>
            </div>
            <p class="product-description">{{ selectedProduct.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';

import 'swiper/css';

import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import {Autoplay,FreeMode, Pagination} from 'swiper/modules';


export default {
  name: 'ProductPage',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay,FreeMode, Pagination],
    };
  },
  data() {
    return {
      selectedProduct: null,
      windowWidth: window.innerWidth, // 添加用于存储窗口宽度的数据属性
      categories: ['臻品系列', '定制系列'], // 这里应该是你的产品分类
      products: [
        {
          id: 15,
          category: '臻品系列',
          name: '怀国15',
          image: require('@/assets/product/best/gb15.png'),
          series: '酱香型白酒',
          alcoholContent: '53%vol',
          volume: '500ml',
          origin: '贵州茅台镇',
          description: '千禧年纯粮酿造陶坛封存大曲坤沙酒，匠心巨作颜值言品质好，酒液粘稠拉丝连绵不断，酒液琥珀色。浓郁的酱香中带有老酒陈香。任何一瓶老酒都是不可复制的传奇。'
        },
        {
          id: 16,
          category: '臻品系列',
          name: '怀国20',
          image: require('@/assets/product/best/gb20.png'),
          series: '酱香型白酒',
          alcoholContent: '53%vol',
          volume: '500ml',
          origin: '贵州茅台镇',
          description: '采用镇厂大曲坤沙酒体，众所周知时间是历史的沉淀，多年的珍藏老酒也是一个酒厂实力的象征。镇厂老酒稀缺不可再生越用越少，本次少量取酒回馈老用户，想品味历史陈香的不要错过了。'
        },
        /*{
          id: 17,
          category: '臻品系列',
          name: '怀国-茅香三号',
          image: require('@/assets/product/best/3h.png'),
          series: '酱香型白酒',
          alcoholContent: '53%vol',
          volume: '500ml',
          origin: '贵州茅台镇',
          description: '采用传承自“国酒大师”郑义兴的酿造技艺之法酿造;褪去繁复外表，以醇厚细腻的品质唤醒酱酒感官体验，回归酱酒本来的味道;是一款好喝不腻，性价比高的正宗坤沙酒。'
        },
        {
          id: 18,
          category: '臻品系列',
          name: '怀国-大国工匠',
          image: require('@/assets/product/best/dggj.png'),
          series: '酱香型白酒',
          alcoholContent: '53%vol',
          volume: '500ml',
          origin: '贵州茅台镇',
          description: '帝皇黄条形盒装设计，内瓶采用五支不同颜色分别代表秦、汉、唐、明、清寓意为公正、纯洁.端庄、正直、优雅。每一口酒都带着大师们的匠心和智慧。'
        },
        {
          id: 19,
          category: '臻品系列',
          name: '怀国-玉玺酒',
          image: require('@/assets/product/best/yxj.png'),
          series: '酱香型白酒',
          alcoholContent: '53%vol',
          volume: '500ml',
          origin: '贵州茅台镇',
          description: '故宫门型设计书本盒，搭配红色玉玺瓶身融入寓有滋生、助长之意，以示兴旺发达，尽显尊贵气质，酒体芳香浓郁、绵柔甘冽、香味协调、入口甜、落口绵、尾净余长。'
        },
        {
          id: 20,
          category: '臻品系列',
          name: '贵州怀国酒·1895',
          image: require('@/assets/product/best/1895.png'),
          series: '酱香型白酒',
          alcoholContent: '53%vol',
          volume: '500ml',
          origin: '贵州茅台镇',
          description: '瓶身采用黑金色，象征着奢华与尊贵。印有郑义兴生平成就，纪念酱香传奇人物一郑义兴(出生于1895年)对中国酱香酒的贡献。酒体芳香醇厚，优雅细腻、回味悠长，酱香味更突出。'
        },*/
        {
          id: 21,
          category: '臻品系列',
          name: '怀国酱酒',
          image: require('@/assets/product/best/bnm.png'),
          series: '酱香型白酒',
          alcoholContent: '53%vol',
          volume: '500ml',
          origin: '贵州茅台镇',
          description: '采用茅台酒厂老厂长“国酒大师郑义兴秘方所酿;与茅台酒同根同源同艺，酱香经典，金奖品质纪念款，纪念1915年巴拿马万国博览会获金奖，百年酱香，荣耀再启的匠心力作。'
        },
        {
          id: 9,
          category: '臻品系列',
          name: '怀国-1935',
          image: require('@/assets/product/quality/1935.png'),
          series: '酱香型白酒',
          alcoholContent: '53%vol',
          volume: '500ml',
          origin: '贵州茅台镇',
          description: '这款怀国1935酒是怀国酒业生产的纯粮食酒，采用的是非常稀少的一个中国红手工盒包装，彰显大气漂亮，酒色微黄，酒花饱满，酒线悠长，挂杯非常明显，口感酒质极好，入口非常绵柔。'
        },
        {
          id: 7,
          category: '臻品系列',
          name: '怀国-千里江山',
          image: require('@/assets/product/quality/qljs.png'),
          series: '酱香型白酒',
          alcoholContent: '53%vol',
          volume: '500ml',
          origin: '贵州茅台镇',
          description: '怀国千里江山瓶身烙印中国十大传世名画之一《千里江山图》，绵延千里的高峰巨岭和平波湖。瓶盖采用浮雕凸显大美山川。开瓶酱香飘然，入口滋味醇厚,饱满协调,口感丰富细腻,甘冽绵长。'
        },
        /*{
          id: 22,
          category: '臻品系列',
          name: '怀国-1949',
          image: require('@/assets/product/best/1949.png'),
          series: '酱香型白酒',
          alcoholContent: '53%vol',
          volume: '500ml',
          origin: '贵州茅台镇',
          description: '这款酒是怀国酒厂调酒师第一小组组长入职时小批量封坛最后一批纪念款酒，数量稀少及其珍贵。粮香四溢，入口绵柔酸甜苦辣咸涩层次分明，回味悠久。酒体性价比高。'
        },*/
        {
          id: 23,
          category: '定制系列',
          name: '婚宴用酒定制',
          image: require('@/assets/product/made/made1.png'),
        },
        {
          id: 24,
          category: '定制系列',
          name: '喜宴用酒定制',
          image: require('@/assets/product/made/made2.png'),
        },
        {
          id: 25,
          category: '定制系列',
          name: '家宴用酒定制',
          image: require('@/assets/product/made/made3.png'),
        },
        {
          id: 26,
          category: '定制系列',
          name: '企业用酒定制',
          image: require('@/assets/product/made/made4.png'),
        },
      ]
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize); // 在组件创建时添加 resize 事件监听
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize); // 在组件销毁时移除事件监听
  },
  mounted() {
    if (this.$route.hash) {
      const element = document.getElementById(this.$route.hash.substring(1));
      if (element) {
        element.scrollIntoView({behavior: "smooth"});
        const rect = element.getBoundingClientRect();
        const top = rect.top + window.scrollY - 100;
        window.scrollTo({
          top: top,
          behavior: 'smooth'
        });
      }
    }
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth; // 更新窗口宽度
    },
    getSlidesPerView(slides) {
      return this.windowWidth <= 768 ? 2 : slides; // 如果屏幕宽度小于或等于 768px，则显示一个滑块，否则显示 3 个
    },
    scrollToCategory(category) {
      const categoryElement = this.$refs[category];
      if (categoryElement) {
        categoryElement.scrollIntoView({behavior: 'smooth'});
      }
    },
    getProductsByCategory(category) {
      return this.products.filter(product => product.category === category);
    },
    showProductDetails(product) {
      this.selectedProduct = product;
    }
  }
}
</script>

<style scoped>
/* 页面基础样式 */
.product-page {
  background-color: #f8f9fa;
  text-align: center;
  font-family: 'Arial', sans-serif; /* 使用合适的字体 */
}

/* 导航栏样式 */
.navbar {
  position: fixed; /* 固定导航栏 */
  top: 106px; /* 导航栏距离页面顶部的距离 */
  left: 0; /* 导航栏距离页面左边的距离 */
  width: 100%; /* 导航栏宽度 */
  z-index: 1; /* 确保导航栏在其他内容之上 */
  background-color: white; /* 背景色 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 可选：添加阴影效果 */

  height: 50px;
  padding: 15px 0px;
  display: flex;
  justify-content: center;
  background-color: white;
}


.navbar .nav-link {
  margin: 0 40px; /* 增加左右间距 */
  text-decoration: none;
  color: #333;
  cursor: pointer;
  font-size: 20px; /* 字体大小 */
}

/* 当悬浮在链接上时的样式 */
.navbar .nav-link:hover {
  color: #1c61ae;
}

.category-text {
  font-size: 35px;
  margin: 20px 0px 0px 10px;
}

#ration-series{
  height: 600px;
  z-index: 0;
}

#quality-series{
  height: 600px;
  z-index: 0;
}

#premium-series{
  height: 600px;
  z-index: 0;
}

#made-series{
  height: 600px;
  z-index: 0;
}


/* 产品列表样式 */
.product-list {
  background-color: #f8f9fa;
  padding: 90px; /* 不同系列之间的间距 */
  background-image: url('~@/assets/product/product-background.jpg');
}

/* 系列内的产品样式 */
.products {
  display: flex; /* 横向排列 */
  flex-wrap: wrap; /* 如果一行放不下，则换行显示 */
  justify-content: left; /* 居中对齐 */
  gap: 20px; /* 产品之间的间隙 */
}

.product-item {
  height: 500px;
  width: 1500px;
}

.product-item img {
  width: 180px; /* 图片宽度自适应产品宽度 */
  height: auto; /* 图片高度自适应 */
  padding-top: 100px;
  margin-bottom: 10px; /* 图片与产品信息的间距 */
  cursor: pointer; /* 鼠标悬浮时变为手形图标 */
}

.product-list-name {
  margin-top: 50px;
  font-size: 18px;
  cursor: pointer; /* 鼠标悬浮时变为手形图标 */
}

.product-info {
  margin-top: 30px;
  text-align: center; /* 产品信息文本左对齐 */
}

.product-info h3 {
  margin: 0; /* 标题的上下边距 */
  color: black; /* 标题颜色 */
  font-size: 18px; /* 标题字体大小 */
}



/* 产品详情弹出框样式 */
.product-details-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.product-details-content {
  background-image: url('~@/assets/product/product-detail-background.jpg');
  width: 1400px;
  height: 700px;
  display: flex;
  position: relative;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.product-details-body {
  display: flex;
  align-items: flex-start;
}

.product-details-image {
  padding: 100px 0px 0px 100px;
  width: 400px;
  height: auto;
}

.product-details-text {
  max-width: 50%;
  margin: 200px 0px 0px 100px;
}

.product-name {
  font-size: 50px;
  margin-top: 0;
  text-align: left;
}

.divider {
  border: none;
  border-top: 1px solid black;
  margin: 5px 0;
}

.product-info-div {
  text-align: left;
}

.product-info {
  margin-right: 20px;
  text-align: left;
}

.product-description {
  text-align: left;
  font-size: 20px;
  margin-top: 50px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .navbar {
    padding: 10px 0;
  }

  .navbar .nav-link {
    margin: 10px 0;
    font-size: 16px;
    padding-right: 10px;
  }
  .product-list-name {
    font-size: 16px;
  }
  .category-text {
    font-size: 30px;
    margin: 20px 0px 0px 10px;
  }
  .product-list {
    padding: 80px 0px 0px 0px
  }
  .product-item {
    height: 500px;
    width: 400px;
  }
  .product-item img {
    width: 150px; /* 图片宽度自适应产品宽度 */
    height: auto; /* 图片高度自适应 */
    padding-top: 50px;
    margin-bottom: 10px; /* 图片与产品信息的间距 */
  }
  #ration-series{
    height: 420px;
  }
  #quality-series{
    height: 420px;
  }
  #premium-series{
    height: 420px;
  }
  #made-series{
    height: 420px;
  }


  .product-details-modal {
    align-items: center; /* 确保居中对齐 */
  }

  .product-details-content {
    flex-direction: column; /* 堆叠布局 */
    width: 90%; /* 宽度调整为屏幕宽度的 90% */
    height: auto; /* 高度自适应 */
    padding: 15px; /* 内部间距 */
    overflow-y: auto; /* 超出内容可滚动 */
  }

  .product-details-body {
    flex-direction: column; /* 堆叠布局 */
    align-items: center; /* 居中对齐 */
  }

  .product-details-image {
    width: 80%; /* 图片宽度调整 */
    padding: 0; /* 去除内部间距 */
    margin-bottom: 20px; /* 图片与文本间距 */
  }

  .product-details-text {
    max-width: 100%; /* 文本最大宽度 */
    margin: 0; /* 去除外部间距 */
  }

  .product-name {
    font-size: 30px; /* 调整标题字体大小 */
  }

  .product-info-div, .product-description {
    font-size: 16px; /* 调整文本字体大小 */
  }

  .close-button {
    font-size: 1.2rem; /* 调整关闭按钮大小 */
  }
}
</style>
