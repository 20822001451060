<template>
  <div>
    <swiper
        class="swiper-container"
        style="width: 100%;height: 88vh"
        direction="vertical"
        :modules="modules"
        :mousewheel="true"
        :pagination="{el: '.swiper-pagination',clickable: true}"
        :scrollbar="{ draggable: true }"
        @swiper="onSwiper"
        @slideChange="onSlideChange">

      <!-- 使用 component is 动态渲染组件 -->
      <swiper-slide v-for="(item) in items"  :key="item.title">
        <component :is="item.component" v-bind="item.props"></component>
      </swiper-slide>
      <div class="swiper-pagination"></div>
    </swiper>
  </div>
  <div class="phoneClass">
    <!-- 使用 component is 动态渲染组件 -->
    <div v-for="(item) in items"  :key="item.title">
      <component :is="item.component" v-bind="item.props"></component>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import {Mousewheel, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import HomeCarouselPage from "@/components/home/HomeCarouselPage";
import HomeProduct from "@/components/home/HomeProduct";
import HomeCompanyInst from "@/components/home/HomeCompanyInst";
import HomeNews from "@/components/home/HomeNews";
import FooterPage from "@/components/home/FooterPage";

// Import Swiper styles
export default {
  name:"HomeBodyPage",
  components: {
    Swiper,
    SwiperSlide,
    FooterPage,
    HomeCarouselPage,
    HomeProduct,
    HomeCompanyInst,
    HomeNews,
  },
  data() {
    return {
      items: [
        { title: '轮播图', component: HomeCarouselPage, props: {} },
        { title: '产品列表', component: HomeProduct, props: {} },
        { title: '集团介绍', component: HomeCompanyInst, props: {} },
        { title: '集团新闻', component: HomeNews, props: {} },
        { title: '底部', component: FooterPage, props: {} },
      ],
    }
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Mousewheel,Pagination],
    };
  },
};
</script>

<style scoped>
.swiper-container {
  display: block;
}

.phoneClass{
  display: none;
}

:deep(.swiper-pagination-bullet) {
  background: transparent;
  border: 2px solid #666666; /* 根据需要调整颜色 */
  width: 10px;
  height: 10px;
  opacity: 1;
}

:deep(.swiper-pagination-bullet-active) {
  background: transparent;
  border: 2px solid #1c61ae; /* 根据需要调整颜色 */
  width: 10px; /* 增大尺寸以突出显示 */
  height: 10px; /* 增大尺寸以突出显示 */
}

@media (max-width: 768px) {
  .swiper-container {
    display: none; /* 在手机端隐藏swiper */
  }
  .phoneClass{
    display: block;
  }
}
</style>
