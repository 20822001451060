<template>
  <div class="environment-page">
    <!-- 导航栏 -->
    <div class="navbar">
      <router-link class="nav-link" :to="{ name: 'factoryEnvironment', hash: '#production' }"
                   :class="{ active: showProduction }"
                   @click="toggleEnvironment('production')">
        生产基地
      </router-link>
      <router-link class="nav-link" :to="{ name: 'factoryEnvironment', hash: '#company' }"
                   :class="{ active: !showProduction }"
                   @click="toggleEnvironment('company')">
        公司环境
      </router-link>
    </div>

    <div id="production" v-if="showProduction" class="environment-list">
      <div v-for="(image, index) in productionImages" :key="index" class="image-container">
        <img :src="image.url" alt="怀国酒业生产基地" @click="openProductionImageModal(index)"/>
      </div>

      <!-- 图片模态框 -->
      <div v-if="showProductionModal" class="modal">
        <span class="close" @click="closeProductionModal">&times;</span>
        <img :src="productionImages[currentProductionImageIndex].url" alt="怀国酒业生产基地" class="modal-content">
        <a class="prev" @click="changeProductionImage(-1)">&#10094;</a>
        <a class="next" @click="changeProductionImage(1)">&#10095;</a>
      </div>
    </div>

    <div id="company" v-if="!showProduction" class="environment-list">
      <div v-for="(image, index) in companyImages" :key="index" class="image-container">
        <img :src="image.url" alt="怀国酒业公司环境" @click="openImageModal(index)"/>
      </div>

      <!-- 图片模态框 -->
      <div v-if="showModal" class="modal">
        <span class="close" @click="closeModal">&times;</span>
        <img :src="companyImages[currentImageIndex].url" class="modal-content" alt="怀国酒业公司环境">
        <a class="prev" @click="changeImage(-1)">&#10094;</a>
        <a class="next" @click="changeImage(1)">&#10095;</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FactoryEnvironment",
  data() {
    return {
      showProduction: false, // 控制是否显示生产基地内容
      productionImages: this.createProductionImageArray(48), // 创建包含25张图片的数组
      companyImages: this.createImageArray(25), // 创建包含25张图片的数组
      showProductionModal: false,
      showModal: false,
      currentProductionImageIndex: 0,
      currentImageIndex: 0,
    };
  },
  mounted() {
    this.updateEnvironmentBasedOnHash();
  },
  watch: {
    '$route': function () {
      this.updateEnvironmentBasedOnHash();
    }
  },
  methods: {
    updateEnvironmentBasedOnHash() {
      if (this.$route.hash === '#production') {
        this.showProduction = true;
      } else if (this.$route.hash === '#company') {
        this.showProduction = false;
      }
    },
    createProductionImageArray(count) {
      let images = [];
      for (let i = 1; i <= count; i++) {
        images.push({url: require(`@/assets/environment/production/${i}.jpg`)});
      }
      return images;
    },
    createImageArray(count) {
      let images = [];
      for (let i = 1; i <= count; i++) {
        images.push({url: require(`@/assets/environment/company/${i}.jpg`)});
      }
      return images;
    },
    toggleEnvironment(type) {
      this.showProduction = (type === 'production');
    },
    openProductionImageModal(index) {
      console.log("Opening modal for image:", index); // 控制台输出，用于调试
      this.currentProductionImageIndex = index;
      this.showProductionModal = true;
    },
    openImageModal(index) {
      console.log("Opening modal for image:", index); // 控制台输出，用于调试
      this.currentImageIndex = index;
      this.showModal = true;
    },
    closeProductionModal() {
      this.showProductionModal = false;
    },
    closeModal() {
      this.showModal = false;
    },
    changeProductionImage(direction) {
      this.currentProductionImageIndex += direction;
      if (this.currentProductionImageIndex >= this.productionImages.length) {
        this.currentProductionImageIndex = 0;
      }
      if (this.currentProductionImageIndex < 0) {
        this.currentProductionImageIndex = this.productionImages.length - 1;
      }
    },
    changeImage(direction) {
      this.currentImageIndex += direction;
      if (this.currentImageIndex >= this.companyImages.length) {
        this.currentImageIndex = 0;
      }
      if (this.currentImageIndex < 0) {
        this.currentImageIndex = this.companyImages.length - 1;
      }
    }
  }
}
</script>

<style scoped>
/* 页面基础样式 */
.environment-page {
  text-align: center;
  font-family: 'Arial', sans-serif; /* 使用合适的字体 */
}

/* 导航栏样式 */
.navbar {
  position: fixed; /* 固定导航栏 */
  top: 106px; /* 导航栏距离页面顶部的距离 */
  left: 0; /* 导航栏距离页面左边的距离 */
  width: 100%; /* 导航栏宽度 */
  z-index: 1; /* 确保导航栏在其他内容之上 */
  background-color: white; /* 背景色 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 可选：添加阴影效果 */

  height: 50px;
  padding: 15px 0px;
  display: flex;
  justify-content: center;
  background-color: white;
}


.navbar .nav-link {
  margin: 0 40px; /* 增加左右间距 */
  text-decoration: none;
  color: #333;
  cursor: pointer;
  font-size: 20px; /* 字体大小 */
}

/* 当悬浮在链接上时的样式 */
.navbar .nav-link:hover {
  color: #1c61ae;
}

.navbar .nav-link.active {
  color: #1c61ae; /* 活跃链接的颜色 */
}

.environment-list {
  padding-top: 80px;
  background-image: url('~@/assets/product/product-background.jpg');
}

/* 图片容器样式 */
.environment-list .image-container {
  display: inline-block;
  margin: 20px;
  width: calc(25% - 20px);
  box-sizing: border-box;
}

.environment-list .image-container img {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 8px; /* 边角圆滑 */
}


/* 模态框样式 */
.modal {
  margin-top: 100px;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  margin: 100px auto;
  display: block;
  width: 80%;
  max-width: 800px;
}

.close {
  position: absolute;
  top: 20px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.prev {
  left: 0;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}


@media screen and (max-width: 768px) {
  /* 导航栏样式调整 */
  .navbar {
    padding: 10px 0;
  }

  .navbar .nav-link {
    margin: 10px 0;
    font-size: 16px;
    padding-right: 10px;
  }

  /* 内容区域样式调整 */
  .environment-list .image-container {
    width: calc(40% - 20px); /* 在手机端显示两列图片 */
  }

  /* 模态框样式调整 */
  .modal-content {
    padding-top: 180px;
    width: 90%;
  }

  .close {
    top: 180px;
    right: 20px;
    font-size: 30px;
  }

  .prev, .next {
    font-size: 18px;
    padding: 12px;
  }
}


</style>
