<template>
  <div>
    <div id="company-profile" class="company-profile">
      <div class="title">
        集团简介
      </div>
      <hr class="title-divider">
      <div class="company-profile-content">
        <div class="leftPanel">
          <div class="company-profile-text">
            <p>贵州怀国酒业(集团)有限公司位于中国酱酒核心产区茅台镇，生产基地离赤水河仅30多米；制酒、制曲车
              间沿赤水而建，厂区地表为茅台红壤，用水从赤水河直接引入，海拔高度450米，与国酒茅台同享得天独厚的地
              理、气候和微生物环境，有着得天独厚的地理位置。</p>
            <p>建有4大生产及包装厂区，现占地300余亩；建有大曲酱香酒窖池200余口；拥有一套完整的质量管理体系
              和多条机械化生产线。年生产优质酱香基酒5000多吨；库存年份老酒千余吨；厂区建设露天大型储酒罐138
              个；储酒能力达1万多吨，是茅台镇酱酒核心产区的大型酿酒企业。</p>
            <p>怀国酒由贵州怀国酒业集团联合中国白酒研究中心、贵州陈酿酱香白酒研究院共同研发，以茅台镇本地特
              制糯高梁、小麦、赤水河河水为原料，严格按照“国酒大师”茅台老厂长郑义兴百年酿造秘方，集结多位大师
              酿造智慧，匠心传承茅台镇12987大曲坤沙工艺，手工酿制，与茅台同根同源同艺，传承茅香品质。</p>
          </div>
        </div>
        <div class="rightPanel">
          <img src="~@/assets/introduction/companyProfileImg.png" style="width:100%;" alt="怀国酒业集团简介">
        </div>
      </div>
    </div>
    <div id="production-process" class="production-process">
      <div class="title">
        生产工艺
      </div>
      <hr class="title-divider">
      <div class="production-process-content">
        <div class="production-process-leftPanel">
          <img src="~@/assets/introduction/process.jpg" style="width:100%;" alt="怀国酒业生产工艺">
        </div>
        <div class="production-process-rightPanel">
          <div class="production-process-text">
            <p>怀国酒业传承“古法酿造、传统工艺、匠心善工”的酿酒工艺，秉持“优质、高产、低耗、均衡、安全”的现代化生产理念，旗下白酒为茅台初代老厂长、国酒大师郑义兴秘方酿造，坚持用5年以上老基酒调酒，坚持12987酿酒工艺，不到时间不取酒，只酿纯粮真酱酒。</p>
            <p>坚持“粮为本”：酿酒原料采用和茅台酒生产所用同品质糯性高粱，当地俗称红缨子高粱，颗粒坚实、饱满、均匀，粒小皮厚，支链淀粉含量达88%，以及精选优质有机小麦，严格按照国家"绿色食品" 、"有机食品"的标准要求进行酿造，坚持好粮酿好酒；</p>
            <p>坚持“艺为髓”，工艺是酿造好酱酒的精髓，怀国酱酒坚持茅台镇“12987”酿酒工艺，即以重阳下沙为始，到第七次取酒完成为一年，1年走完一个周期；经过两次投粮，九次蒸煮，八次发酵，七次取酒；严格遵循国家标准生产酿造白酒。</p>
            <p>坚持“陈为基”，十年磨一剑，做人如此，酿酒亦如此，年陈是出好酒的基石。怀国酱酒坚持用5年以上老基酒。选取特制陶坛存放原酒，在暗光、温度与湿度稳定的环境下存放，氧气进入坛内与酒分子发生陈化反应，通过陶坛壁上细微孔隙，刺激性物质不断挥发，提升酒质。怀国酱酒有20年陈、15年陈、10年陈、8年陈等多层次老酒，酒体充分成熟，酱香幽雅浓郁，口感丰满细腻圆润。</p>
          </div>
        </div>
      </div>
    </div>
    <div id="cultural-concept" class="cultural-concept">
      <div class="title">
        文化理念
      </div>
      <hr class="title-divider">
      <div class="cultural-concept-content">
        <div class="cultural-concept-leftPanel">
          <swiper
              class="cultural-concept-swiper"
              :modules="modules"
              :autoplay="{delay: 3500, disableOnInteraction: false}"
              :loop="true"
              >
            <swiper-slide v-for="(image, index) in images" :key="index">
              <img class="cultural-concept-img" :src="image"  alt="怀国酒业文化理念">
            </swiper-slide>
          </swiper>
        </div>
        <div class="cultural-concept-rightPanel">
          <div class="cultural-concept-text">
            <p><strong>企业使命</strong>酿造美好生活，传承中国文化</p>
            <p><strong>企业理念</strong>怀工匠之心，酿国民之酒</p>
            <p><strong>企业战略</strong>两个“坚持原则”：坚持用“储存五年以上”老基酒、坚持走“高质量服务”发展之路</p>
            <p><strong>愿景</strong>中国十大酱酒品牌、中国互联网温度白酒第一品牌</p>
            <p><strong>价值观</strong>客户第一，品质至上，传承创新，高效发展</p>
          </div>
<!--          <div class="cultural-concept-values-text">
            <p>客户第一：怀国酒业始终坚持把客户放在第一位，对于顾客提出的任何正当、合理的要求，都应想方设法、尽最大努力去满足，让客户成为最大受益者，不断提升客户购买体验，产品体验和美好生活品质。</p>
            <p>品质至上：作为百年白酒企业，怀国秉承“踏踏实实酿造一杯好酱酒”为初心，不断提升酿酒工艺和标准，坚信好产品才是检验市场的唯一真理，用匠人精神酿造中国好酱酒，坚持高质量发展，用品质推动增长，品质征服客户，品质赢得未来。</p>
            <p>传承创新：不断传承中国酿酒工艺和白酒文化，以“文化自信”为出发点，坚持在传承中创新，以创造性转化和发展面对不断变化的市场和时代，才能保持企业活力，也才是对中华文化最好的传承。</p>
            <p>高效发展：作为互联网电商企业，效率最关重要，先完成后完美，简单沟通、高效工作、协同前进，才能最大化的创造个人价值、企业价值和社会价值，才能赢得个人发展、企业发展和社会进步。</p>
          </div>-->
        </div>
      </div>
    </div>
    <div id="brand-story" class="brand-story">
      <div class="title">
        品牌故事
      </div>
      <hr class="title-divider">
      <div class="brand-story-content">
        <div class="leftPanel">
          <img src="~@/assets/introduction/story.jpg" style="width:100%;" alt="怀国酒业品牌故事">
        </div>
        <div class="rightPanel">
          <div class="brand-story-text">
            <p>贵州怀国酒业集团拥有百年酿酒历史，从20世纪初的1908年“实业救国”的热潮中创立，成立私人老烧坊开始酿酒，中间经历了战争关闭、破产倒闭。新中国成立后，国家鼓励白酒产业发展，并制定了酿酒行业十年规划，怀国酒业恢复生产。</p>
            <p>改革开放后，响应国家“普通酒向优质酒转变”政策，怀国酒业开始技术和配方的创新发展之路，一方面搭建优质高粱产值基地，另一方面精心研制正宗茅香配方，自创117味中草药配方，和茅台初代厂长郑义兴采用同配方。在此期间，贵州各大私营酒厂合并重组改为国有，怀国酒业拒绝收购合并，坚持独立酿酒，直至今日，初步奠定正宗酱酒品牌地位。</p>
            <p>怀国酒业在经历各种变动后走完第一个百年，在过去的百年里，坚持古法纯粮酿造，保留了相对完整、正宗的茅香酿酒工艺和配方。怀国酒业旗下酱酒均为茅台初代老厂长郑义兴秘方所酿，因和茅台同根同源同配方，传承茅香品质，怀国立志让更多人喝到实惠又正宗的茅香酱酒。</p>
            <p>怀国酒业还坚持一种理念：酒是人生伴侣，喝的是温度、是情感、是信念，无论是从酒厂到消费者手中的过程，还是从消费者手中到口中的距离，要让消费者了解并买到真正的好酒，感受高品质、温暖、全链路、全生命周期的品质服务。于是怀国酒业立志打造白酒行业的“小米”，通过超高性价比提供优质产品和服务，提升人们美好生活品质，并传播好白酒文化和中国传统文化。</p>
          </div>
        </div>
      </div>
    </div>
    <div id="development-path" class="development-path">
      <div class="title">
        发展历程
      </div>
      <hr class="title-divider">
<!--      <div class="development-path-content">
        <div class="development-path-leftPanel">
        </div>
        <div class="development-path-rightPanel">
          <div class="development-path-text">
            <p><strong>1908年</strong>私人老烧坊</p>
            <p><strong>1935年</strong>初创中草药制曲配方</p>
            <p><strong>1983年</strong>复原郑义兴配方，结合郑义兴中草药制曲法，形成117味中草药制曲配方，并全面恢复生产</p>
            <p><strong>2008年</strong>怀国品牌管理有限公司成立，怀国酱酒畅销全国</p>
            <p><strong>2022年</strong>成立怀国酒业集团，走多品牌发展之路</p>
          </div>
        </div>
      </div>-->
    </div>
    <div id="brand-honors" class="brand-honors">
      <div class="title">
        品牌荣誉
      </div>
      <hr class="title-divider">
      <div class="brand-honors-content">
        <div class="brand-honors-leftPanel">
          <div class="brand-honors-text">
            <p>怀国酒业先后荣获2022-2023白酒行业领军品牌金谱奖、利比里亚共和国驻华大使馆指定用酒、CCTV“国货之光”巡展品牌、贵州酒文化研究院副会长单位、十周年国礼品牌、入选品牌强国优选工程、白酒领域3A级信用企业、质量服务信誉3A级示范单位等。</p>
          </div>
        </div>
        <div class="brand-honors-rightPanel">
          <img src="~@/assets/introduction/honors/honors.jpg" alt="怀国酒业品牌荣誉">
        </div>
      </div>
    </div>
    <div id="social-responsibility" class="social-responsibility">
      <div class="title">
        社会责任
      </div>
      <hr class="title-divider">
      <div class="cultural-concept-content">
        <div class="cultural-concept-leftPanel">
          <swiper
              class="cultural-concept-swiper"
              :modules="modules"
              :autoplay="{delay: 3500, disableOnInteraction: false}"
              :loop="true"
          >
            <swiper-slide v-for="(image, index) in socialImages" :key="index">
              <img class="cultural-concept-img" :src="image" alt="怀国酒业社会责任">
            </swiper-slide>
          </swiper>
        </div>
        <div class="cultural-concept-rightPanel">
          <div class="social-responsibility-text">
            <p>驰援河北灾区</p>
            <p>援建资兴公益行动</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

export default {
  name: "IntroductionPage",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      title:"怀国公司简介",
      images: [
        require('@/assets/introduction/culture/culture1.jpg'),
        require('@/assets/introduction/culture/culture2.jpg'),
      ],
      socialImages: [
        require('@/assets/introduction/social.jpg'),
      ],
      honors: [
        { img: require('@/assets/introduction/honors/honors1.png'), title: '《中国品牌节“金谱奖”·白酒行业领军品牌》' },
        { img: require('@/assets/introduction/honors/honors2.jpeg'), title: '《CCTV·国货之光》' },
        { img: require('@/assets/introduction/honors/honors3.jpeg'), title: '《利比里亚驻华大使馆指定用酒》' },
        { img: require('@/assets/introduction/honors/honors4.jpeg'), title: '《贵州省酒文化研究会·副会长单位》' },
        { img: require('@/assets/introduction/honors/honors5.png'), title: '《CCTV央视展播品牌》' },
        { img: require('@/assets/introduction/honors/honors6.jpeg'), title: '《品牌强国·白酒行业优选成员单位》' },
        { img: require('@/assets/introduction/honors/honors7.jpeg'), title: '《·十周年国礼品牌》' },
        { img: require('@/assets/introduction/honors/honors8.png'), title: '《腾讯优秀合作伙伴/广告先锋/素材专家》' },
      ],
    }
  },
  mounted() {
    if (this.$route.hash) {
      const element = document.getElementById(this.$route.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        const rect = element.getBoundingClientRect();
        const top = rect.top + window.scrollY - 100;
        window.scrollTo({
          top: top,
          behavior: 'smooth'
        });
      }
    }
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>

<style scoped>

.title {
  text-align: center; /* 标题居中 */
  padding: 20px; /* 添加一些下边距 */
  display: block;
  font-size: 32px;
  font-family: SourceHanSansCNBold;
}

.title-divider {
  margin-top: 5px; /* 在标题下方留出20px的空间 */
  border: 0; /* 移除默认边框 */
  height: 2px; /* 线条高度 */
  background-color: #333; /* 线条颜色，可根据设计调整 */
  width: 3%; /* 线条宽度，可根据设计调整 */
  margin-left: auto; /* 水平居中 */
  margin-right: auto; /* 水平居中 */
}

/*公司简介*/
.company-profile{
  height: 800px;
  background-image: url('~@/assets/introduction/background.jpg');
  border-bottom: 1px solid #f0f0f0;
}


.company-profile-content {
  max-width: 1700px;
  margin: 0 auto; /* 水平居中 */
  display: flex;
  align-items: start;
  gap: 20px;
  padding: 0 100px; /* 在左右两侧添加100px的留白 */
}


.leftPanel {
  flex-basis: 50%;
}
.leftPanel img {
  height: 560px; /* 设置最大高度为 560px */
  width: auto; /* 宽度自动调整以保持图片比例 */
}

.rightPanel {
  flex-basis: 50%;
  overflow-y: auto;
}

.rightPanel img {
  margin-top: 20px;
  height: 600px; /* 设置最大高度为 560px */
  width: auto; /* 宽度自动调整以保持图片比例 */
}


.company-profile-text {
  font-size: 1.2rem;
  padding: 40px;
}

.company-profile-text p {
  margin-bottom: 20px; /* 段落之间的间距 */
  line-height: 1.8; /* 行高，提高可读性 */
  text-align: justify; /* 文本对齐方式，可选 */
  text-indent: 2em; /* 首行缩进两个字符的宽度 */
}

/*生产工艺*/
.production-process{
  height: 750px;
  background-image: url('~@/assets/introduction/background.jpg');
  border-bottom: 1px solid #f0f0f0;
}
.production-process-leftPanel {
  padding-top: 30px;
}
.production-process-leftPanel img {
  height: 560px; /* 设置最大高度为 560px */
  width: 700px; /* 宽度自动调整以保持图片比例 */
}
.production-process-rightPanel {
  flex-basis: 50%;
  padding-top: 30px;
}
.production-process-content {
  max-width: 1700px;
  margin: 0 auto; /* 水平居中 */
  display: flex;
  align-items: start;
  gap: 20px;
  padding: 0 100px; /* 在左右两侧添加100px的留白 */
}
.production-process-text p {
  font-size: 20px;
  margin-bottom: 20px; /* 段落之间的间距 */
  line-height: 1.3; /* 行高，提高可读性 */
  text-align: justify; /* 文本对齐方式，可选 */
  text-indent: 2em; /* 首行缩进两个字符的宽度 */
}

/*文化理念*/
.cultural-concept{
  background-image: url('~@/assets/introduction/background.jpg');
  height: 800px;
  border-bottom: 1px solid #f0f0f0;
}
.cultural-concept-content {
  max-width: 1800px;
  margin: 0 auto; /* 水平居中 */
  display: flex;
  align-items: start;
  gap: 20px;
  padding: 0 100px; /* 在左右两侧添加100px的留白 */
}

.cultural-concept-leftPanel {
  padding-top:40px
}
.cultural-concept-rightPanel {
  padding-left: 30px;
}

.cultural-concept-swiper{
  width: 900px;
  height: 650px;
}
.cultural-concept-img {
  height: 100%; /* 图片高度固定为视窗高度的 88% */
  width: 100%; /* 图片宽度适应容器宽度 */
  object-fit: cover; /* 图像覆盖整个区域，如果不想裁剪图片，可以使用 contain */
}

.cultural-concept-text {
  line-height: 1.5;
  color: #333; /* 文本颜色，根据您的需求调整 */
  margin-bottom: 20px; /* 段落间距 */
  padding-top: 30px;
}

.cultural-concept-text p {
  font-size: 20px; /* 根据实际需求调整字体大小 */
  margin-bottom: 22px; /* 段落之间的间距，根据实际需求调整 */
}

.cultural-concept-text strong {
  font-size: 32px; /* 根据实际需求调整字体大小 */
  font-weight: bold; /* 加粗标题 */
  display: block; /* 使标题单独一行 */
  margin-bottom: 8px; /* 标题下的间距 */
}

/*品牌故事*/
.brand-story{
  background-image: url('~@/assets/introduction/background.jpg');
  border-bottom: 1px solid #f0f0f0;
  height: 780px;
}

.brand-story-content {
  max-width: 1800px;
  margin: 0 auto; /* 水平居中 */
  display: flex;
  align-items: start;
  gap: 20px;
  padding: 0 100px; /* 在左右两侧添加100px的留白 */
}

.brand-story-text {
  font-size: 18px;
  padding: 30px 40px 0px 30px;
}

.brand-story-text p {
  margin-bottom: 20px; /* 段落之间的间距 */
  line-height: 1.6; /* 行高，提高可读性 */
  text-align: justify; /* 文本对齐方式，可选 */
  text-indent: 2em; /* 首行缩进两个字符的宽度 */
}

/*发展历程*/
.development-path {
  background-image: url('~@/assets/introduction/development-path.jpg');
  background-repeat: no-repeat;
  background-size: contain; /* Makes sure the entire image is visible */
  background-position: center;
  min-height: 600px;
  max-width: 1900px;
  margin: 0 auto;
}


.development-path-content {
  max-width: 1900px;
  margin: 0 auto; /* 水平居中 */
  display: flex;
  align-items: start;
  gap: 20px;
}

.development-path-leftPanel {
  flex-basis: 50%;
}
.development-path-rightPanel {
  margin-top: 20px;
  flex-basis: 50%;
}

.development-path-text {
  line-height: 1.6;
  color: #333; /* 文本颜色，根据您的需求调整 */
}

.development-path-text p {
  font-size: 20px; /* 根据实际需求调整字体大小 */
}

.development-path-text strong {
  font-size: 32px; /* 根据实际需求调整字体大小 */
  font-weight: bold; /* 加粗标题 */
  display: block; /* 使标题单独一行 */
}

/*品牌荣誉*/
.brand-honors{
  background-image: url('~@/assets/introduction/background.jpg');
  height: 800px;
  border-bottom: 1px solid #f0f0f0;
}
.brand-honors-content {
  max-width: 1900px;
  margin: 0 auto; /* 水平居中 */
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 创建四列的网格 */
  gap: 20px; /* 网格项目之间的间距 */
  padding: 20px 200px 0px 200px; /* 容器内部的填充 */
}
.brand-honors-leftPanel {
  min-width: 600px;
  flex-basis: 50%;
}

.brand-honors-text{
  margin-bottom: 20px; /* 段落之间的间距 */
  text-align: justify; /* 文本对齐方式，可选 */
  text-indent: 2em; /* 首行缩进两个字符的宽度 */
  font-size: 20px;
  line-height: 4; /* 行高，提高可读性 */
  padding-right: 10px;
  padding-top: 100px;
}

.brand-honors-rightPanel {
  flex-basis: 30%;
}
.brand-honors-rightPanel img{
  max-height: 600px;
}

/*社会责任*/
.social-responsibility{
  background-image: url('~@/assets/introduction/background.jpg');
  height: 900px;
  border-bottom: 1px solid #f0f0f0;
}

.social-responsibility-text {
  line-height: 1.6;
  color: #333; /* 文本颜色，根据您的需求调整 */
  margin-bottom: 20px; /* 段落间距 */
  padding-top: 200px;
}

.social-responsibility-text p {
  font-size: 20px; /* 根据实际需求调整字体大小 */
  margin-bottom: 20px; /* 段落之间的间距，根据实际需求调整 */
}

@media (max-width: 768px) {

  .title {
    padding: 10px; /* 添加一些下边距 */
    font-size: 20px;
  }

  .title-divider {
    width: 10%; /* 线条宽度，可根据设计调整 */
  }


  /* 公司简介、生产工艺、文化理念、品牌故事、发展历程、品牌荣誉、社会责任等各部分的共同样式 */
  .company-profile, .production-process, .cultural-concept, .brand-story, .development-path, .brand-honors, .social-responsibility {
    height: auto; /* 高度自适应 */
  }

  .leftPanel img{
    height: 380px;
  }

  .rightPanel img{
    height: 380px;
  }

  .production-process-leftPanel img {
    height: 300px;
  }

  .production-process-rightPanel {
    padding: 20px;
  }

  .cultural-concept-text {
    line-height: 1.5;
    margin-bottom: 20px; /* 段落间距 */
    padding-top: 30px;
  }

  .cultural-concept-text p {
    padding-right: 20px;
    font-size: 16px; /* 根据实际需求调整字体大小 */
    margin-bottom: 22px; /* 段落之间的间距，根据实际需求调整 */
  }

  .cultural-concept-text strong {
    margin-bottom: 8px; /* 标题下的间距 */
    font-size: 28px; /* 根据实际需求调整字体大小 */
    font-weight: bold; /* 加粗标题 */
    display: block; /* 使标题单独一行 */
  }

  .brand-honors-leftPanel {
    min-width: auto;
  }

  .brand-honors-text{
    text-indent: 2em; /* 首行缩进两个字符的宽度 */
    font-size: 16px;
    line-height: 2; /* 行高，提高可读性 */
    padding: 20px;
  }

  .brand-honors-rightPanel img{
    width: 100%;
  }

  .company-profile-content, .production-process-content, .cultural-concept-content, .brand-story-content, .development-path-content, .brand-honors-content, .social-responsibility-text {
    display: block; /* 堆叠布局 */
    padding: 0px;
  }

  .leftPanel, .rightPanel, .production-process-leftPanel, .production-process-rightPanel, .cultural-concept-leftPanel, .cultural-concept-rightPanel, .brand-honors-leftPanel, .brand-honors-rightPanel, .development-path-leftPanel, .development-path-rightPanel {
    flex-basis: 100%; /* 宽度调整为100% */
  }

  .company-profile-text, .production-process-text p, .cultural-concept-text, .brand-story-text, .development-path-text, .brand-honors-text, .social-responsibility-text {
    font-size: 16px; /* 减小字体大小 */
  }

  .title {
    font-size: 24px; /* 减小标题字体大小 */
  }

  .swiper {
    height: auto; /* 轮播图高度自适应 */
    width: 100%; /* 轮播图宽度100% */
  }

  .swiper-slide img {
    width: 100%; /* 图片宽度100% */
  }

  .development-path{
    background-image: url('~@/assets/introduction/background.jpg');
  }
  .development-path-text{
    padding: 20px;
  }

  .social-responsibility-text {
    line-height: 1.3;
    margin-bottom: 0px; /* 段落间距 */
    padding: 20px;
  }

  .social-responsibility-text p {
    font-size: 16px; /* 根据实际需求调整字体大小 */
    margin-bottom: 15px; /* 段落之间的间距，根据实际需求调整 */
  }

  /* 调整文本样式 */
  .company-profile-text p, .production-process-text p, .brand-story-text p, .brand-honors-text p,{
    text-align: left; /* 文本左对齐 */
    text-indent: 2em; /* 移除首行缩进 */
  }
}


</style>
